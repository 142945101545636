/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { Key, useEffect, useState } from "react";
import {
  View,
  Flex,
  ProgressCircle,
  ActionGroup,
  Item,
} from "@adobe/react-spectrum";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { useGetFindingsQuery } from "../../../services/supportInsights";
import familyNameToCode from "../../../utils/familyNameToCode";
import Refresh from "@spectrum-icons/workflow/Refresh";
import { setAiSuggestions } from "../../../store/case/caseSlice";
import { fetchAIResponseStream } from "../../../services/requestServices";
import AiMarkdownRenderer from "../AiMarkdownRenderer";

function useAiSuggestions() {
  const dispatch = useDispatch();
  const aiSuggestions = useSelector(
    (state: RootState) => state.case.aiSuggestions,
  );

  const casePayload = useSelector((state: RootState) => state.case.casePayload);
  const caseId = casePayload?.caseId || "";
  const productFamily = casePayload?.productFamilyName || "";
  const productFamilyCode = familyNameToCode(productFamily);
  const [isReloading, setIsReloading] = useState(false);

  const {
    data: findings,
    error: findingsError,
    isLoading: isFindingsLoading,
  } = useGetFindingsQuery(
    {
      caseId: caseId,
      productFamily: productFamilyCode,
    },
    {
      skip: !caseId || !productFamily,
    },
  );

  const reload = () => {
    if (!aiSuggestions?.isLoading) {
      setIsReloading(true);
    }
  };
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    if (
      (!isFindingsLoading &&
        aiSuggestions &&
        !aiSuggestions?.isLoading &&
        !aiSuggestions?.text) ||
      (!aiSuggestions?.isLoading && isReloading)
    ) {
      const requestBody = {
        caseId,
        promptData: { "oasis-findings": findingsError ? [] : findings?.data },
      };
      const url = `${window.env.REACT_APP_API_BASE}dynamics/${caseId}/aiSuggestions`;
      dispatch(setAiSuggestions({ text: "", isLoading: true }));
      fetchAIResponseStream(url, token, requestBody, (response) => {
        dispatch(setAiSuggestions(response));
      })
        .then((response) => {
          if (
            (!aiSuggestions?.isLoading || !isReloading) &&
            (!response || response?.error)
          ) {
            dispatch(
              setAiSuggestions({
                text: "Sorry, but AI suggestions are not available at the moment.",
                isLoading: false,
              }),
            );
          }
        })
        .finally(() => setIsReloading(false));
    }
  }, [
    isReloading,
    caseId,
    findings,
    findingsError,
    dispatch,
    isFindingsLoading,
  ]);

  return [aiSuggestions, reload, isReloading] as const;
}

const AiSuggestions: React.FC = () => {
  const [aiSuggestions, reload, isReloading] = useAiSuggestions();

  return (
    <View>
      <Flex direction="column">
        <View UNSAFE_className="response analytics-generate-ai-suggestions-response">
          <div id={"ai-response"}>
            <AiMarkdownRenderer text={aiSuggestions?.text || ""} />
          </div>
          {(aiSuggestions?.isLoading || isReloading) && (
            <ProgressCircle aria-label="Loading…" isIndeterminate />
          )}
          <Flex justifyContent="start">
            <ActionGroup
              density="compact"
              isQuiet={true}
              onAction={(key: Key) => (key === "regenerate" ? reload() : null)}
            >
              <Item key="regenerate">
                <Refresh
                  UNSAFE_className="analytics-track-me"
                  data-analytics-link-name="Generate Suggestions"
                  data-analytics-view-name="AI Suggestions"
                />
              </Item>
            </ActionGroup>
          </Flex>
        </View>
      </Flex>
    </View>
  );
};

export default AiSuggestions;
