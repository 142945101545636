/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import HomePageCardWrapper from "./HomePageCardWrapper";
import { useGetUserKbArticlesQuery } from "../../services/supportInsightsUserPersona";
import UserKbArticlesTable from "./UserKbArticlesTable";

const UserKbArticlesCard = () => {
  const { data, isLoading, error } = useGetUserKbArticlesQuery();

  // Create a filtered response that excludes any null articles.
  const filteredResponse = data
    ? { ...data, data: data.data.filter((article) => article !== null) }
    : undefined;

  const kbArticles = filteredResponse?.data || [];
  const itemCount = kbArticles.length;

  return (
    <HomePageCardWrapper
      isLoading={isLoading}
      error={error}
      data={kbArticles}
      title="My Contributions"
      itemCount={itemCount}
      noDataTitle="No Knowledge Base Articles Contributed"
      errorMessage="There was an error fetching your knowledge base contributions. Please try again later."
    >
      {filteredResponse && <UserKbArticlesTable data={filteredResponse} />}
    </HomePageCardWrapper>
  );
};

export default UserKbArticlesCard;
