/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect } from "react";
import {
  Divider,
  Flex,
  Grid,
  View,
  minmax,
  repeat,
} from "@adobe/react-spectrum";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../store/store";
import UserTicketWatchlistCard from "../components/userHomePage/UserTicketWatchlistCard";
import UserOrgWatchlistCard from "../components/userHomePage/UserOrgWatchlistCard";
import UserAssignedTicketsCard from "../components/userHomePage/UserAssignedTicketsCard";
import UserKbArticlesCard from "../components/userHomePage/UserKbArticlesCard";
import { resetCase } from "../store/case/caseSlice";
import { resetOrg } from "../store/org/orgSlice";

const UserHomePage: React.FC = () => {
  document.title = "User Home";
  const dispatch = useDispatch();
  const userName = useSelector(
    (state: RootState) => state?.user?.userDetails?.name,
  );

  useEffect(() => {
    dispatch(resetCase());
    dispatch(resetOrg());
  }, [dispatch]);

  return (
    <View
      borderRadius="regular"
      backgroundColor="gray-50"
      paddingY="size-300"
      UNSAFE_className="data-container-wrapper"
      height="95%"
      overflow="auto"
      width="100%"
    >
      <Flex
        direction="row"
        alignContent="center"
        justifyContent="space-between"
        marginX="size-500"
      >
        <p className="user-page-parent-title">Hi {userName || ""}!</p>
      </Flex>
      <Divider
        size="S"
        marginTop="size-150"
        UNSAFE_className="divider-with-drop-shadow"
      />
      <View paddingY="size-300" paddingX="size-500">
        <Grid
          columns={repeat("auto-fit", minmax("450px", "1fr"))}
          gap="size-200"
        >
          <UserAssignedTicketsCard />
          <UserTicketWatchlistCard />
          <UserKbArticlesCard />
          <UserOrgWatchlistCard />
        </Grid>
      </View>
    </View>
  );
};

export default UserHomePage;
