/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */
/**
 * Finds the earliest datapoint based on the `created_at` property.
 * @param items - An array of objects that include a `created_at` field.
 * @returns The `created_at` value of the earliest datapoint, or null if the array is empty.
 */
export function latestCreatedAtDate<T extends { created_at: string }>(
  items: T[],
): string | null {
  if (items.length === 0) {
    return null;
  }

  let lastItem = items[0];

  for (let i = 1; i < items.length; i++) {
    const currentItem = items[i];
    if (currentItem.created_at > lastItem.created_at) {
      lastItem = currentItem;
    }
  }

  return lastItem.created_at;
}
