/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Flex, View } from "@adobe/react-spectrum";
import WatchlistSwitch from "../common/WatchlistSwitch";

interface OrgNameTopBarElementProps {
  orgId: number;
  orgName: string | undefined;
}

const OrgNameOrgInfoBannerElement: React.FC<OrgNameTopBarElementProps> = ({
  orgId,
  orgName,
}) => {
  return (
    <View paddingY="size-200" width="size-3000" paddingStart="size-300">
      <Flex direction="column" gap={5}>
        <span className="account-details-top-bar-org-name">
          {orgName ? orgName : "Org Name Unavailable"}
        </span>
        <WatchlistSwitch itemId={orgId} itemType="org" />
      </Flex>
    </View>
  );
};

export default OrgNameOrgInfoBannerElement;
