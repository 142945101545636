/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { GetUserAssignedTicketsResponse } from "../types/case";
import {
  UserPreferencesMutationArgument,
  UserPreferencesResponse,
} from "../types/UserPreferences";
import * as Watchlist from "../types/watchlist";
import { GetUserKBArticlesResponse } from "../types/knowledgeCenter";

const waitForToken = async (getState: () => RootState): Promise<string> => {
  let token = (getState() as RootState).auth.token;
  while (!token) {
    await new Promise((resolve) => setTimeout(resolve, 100)); // Poll every 100ms
    token = (getState() as RootState).auth.token;
  }
  return token;
};

export const supportInsightsUserPersonaApi = createApi({
  reducerPath: "supportInsightsUserPersonaApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.env.REACT_APP_API_BASE,
    timeout: 600000,
    prepareHeaders: async (headers, { getState }) => {
      const token = await waitForToken(getState as () => RootState);
      if (token) {
        headers.set("X-OKTA-Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Watchlists"],
  endpoints: (builder) => ({
    getUserPreferences: builder.query<UserPreferencesResponse, void>({
      query: () => ({
        url: "user/preferences",
        method: "GET",
      }),
    }),
    getUserAssignedTickets: builder.query<GetUserAssignedTicketsResponse, void>(
      {
        query: () => ({
          url: "user/tickets",
          method: "GET",
        }),
      },
    ),
    getUserKbArticles: builder.query<GetUserKBArticlesResponse, void>({
      query: () => ({
        url: "user/kb-articles",
        method: "GET",
      }),
    }),
    setUserPreferences: builder.mutation<
      UserPreferencesResponse,
      UserPreferencesMutationArgument
    >({
      query: ({ payload }) => ({
        url: "user/preferences",
        method: "PUT",
        body: payload,
      }),
    }),
    checkWatchlistItem: builder.query<
      Watchlist.CheckItemResponse,
      Watchlist.CheckItemArgument
    >({
      query: ({ itemId, itemType }) => ({
        url: `user/watchlist/check?${new URLSearchParams({ itemId: itemId.toString(), itemType: itemType })}`,
        method: "GET",
      }),
      providesTags: ["Watchlists"],
    }),
    removeFromWatchlist: builder.mutation<
      Watchlist.MutationResponse,
      Watchlist.MutationArgument
    >({
      query: ({ payload }) => ({
        url: `user/watchlist`,
        method: "DELETE",
        body: payload,
      }),
      invalidatesTags: ["Watchlists"],
    }),
    addToWatchlist: builder.mutation<
      Watchlist.MutationResponse,
      Watchlist.MutationArgument
    >({
      query: ({ payload }) => ({
        url: `user/watchlist`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["Watchlists"],
    }),
    getUserWatchlist: builder.query<
      Watchlist.GetWatchlistResponse,
      { itemType: "ticket" | "org" }
    >({
      query: ({ itemType }) => ({
        url: `user/watchlist?itemType=${itemType}`,
        method: "GET",
      }),
      providesTags: ["Watchlists"],
    }),
  }),
});

export const {
  useGetUserPreferencesQuery,
  useSetUserPreferencesMutation,
  useCheckWatchlistItemQuery,
  useRemoveFromWatchlistMutation,
  useAddToWatchlistMutation,
  useGetUserWatchlistQuery,
  useGetUserAssignedTicketsQuery,
  useGetUserKbArticlesQuery,
} = supportInsightsUserPersonaApi;
