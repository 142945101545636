/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Flex, Link } from "@adobe/react-spectrum";
import { Link as NavLink } from "react-router-dom";

interface TableRowKeyValuePairBase {
  title: string;
  value: string;
  elementWidth: string; // Requires width percentage in string format
}

interface TableRowKeyValuePairText extends TableRowKeyValuePairBase {
  valueType?: "text"; // Default to "text" if not specified
  linkHref?: never; // Prevents linkHref if valueType is "text"
  target?: never; // Prevents target if valueType is "text"
}

interface TableRowKeyValuePairLink extends TableRowKeyValuePairBase {
  valueType: "link" | "navLink"; // Requires valueType if valueType is "link" or "navLink"
  linkHref: string; // Requires linkHref if valueType is "link" or "navLink"
}

type TableRowKeyValuePairProps =
  | TableRowKeyValuePairText
  | TableRowKeyValuePairLink;

const TableRowKeyValuePair: React.FC<TableRowKeyValuePairProps> = ({
  title,
  value,
  valueType = "text",
  linkHref,
  elementWidth,
}) => {
  return (
    <Flex direction="column" gap="size-50" width={elementWidth}>
      <p className="user-page-table-row-title">{title}</p>
      {valueType === "text" && (
        <p className="user-page-table-row-value">{value}</p>
      )}
      {valueType === "link" && (
        <Link
          isQuiet
          UNSAFE_className="user-page-table-row-link"
          href={linkHref}
          target="_blank"
        >
          {value}
        </Link>
      )}
      {valueType === "navLink" && (
        <NavLink to={linkHref || ""} className="user-page-table-row-link">
          {value}
        </NavLink>
      )}
    </Flex>
  );
};

export default TableRowKeyValuePair;
