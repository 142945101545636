/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import PageWrapper from "../components/common/PageWrapper";
import { Flex } from "@adobe/react-spectrum";
import DataContainer from "../components/common/DataContainer";
import AiSummary from "../components/aiAssistant/AiSummary/AiSummary";

const AiAssistantPage: React.FC = () => {
  document.title = "AI Assistant";

  return (
    <PageWrapper pageName="AI Assistant">
      <Flex direction="column" gap="size-400">
        <DataContainer label="AI Assistant">
          <AiSummary />
        </DataContainer>
      </Flex>
    </PageWrapper>
  );
};

export default AiAssistantPage;
