/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createSlice } from "@reduxjs/toolkit";
import { Organization } from "../../types/org";

const initialState: Organization = {
  id: 0,
  name: "",
  code: "",
  created_at: "",
  updated_at: "",
  internal_notes: [],
};

const orgSlice = createSlice({
  name: "org",
  initialState,
  reducers: {
    setOrg: (state, action) => {
      return action.payload;
    },
    resetOrg: () => initialState,
  },
});

export const { setOrg, resetOrg } = orgSlice.actions;
export default orgSlice.reducer;
