/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Picker, Item, Text } from "@adobe/react-spectrum";
import { useGetProjectQuery } from "../../services/supportInsights";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { setInstanceProjectId } from "../../store/case/caseSlice";

interface ProjectSelectorProps {
  orgId: number;
  productFamilyId: number;
}

const ProjectSelector: React.FC<ProjectSelectorProps> = ({
  productFamilyId,
  orgId,
}) => {
  const dispatch = useDispatch();
  const projectId = useSelector(
    (state: RootState) => state.case.instance.projectId,
  );

  const { data, isLoading } = useGetProjectQuery({
    orgId: orgId,
    productFamilyId: productFamilyId,
  });

  if (data?.data && data.data.length > 0) {
    return (
      <Picker
        selectedKey={projectId}
        placeholder="Select An Instance"
        label="Provisioned Instance (Project, Program)"
        width="250px"
        onSelectionChange={(selected) =>
          dispatch(setInstanceProjectId(selected))
        }
      >
        {data.data.map((project) => (
          <Item key={project.id}>
            <Text>{project.name}</Text>
            <Text slot="description">{project.code}</Text>
          </Item>
        ))}
      </Picker>
    );
  }

  if (isLoading) {
    return (
      <Picker placeholder="Loading" label="Provisioned Instance (Project, Program)" isDisabled>
        <Item key="loading">Loading </Item>
      </Picker>
    );
  }

  return null;
};

export default ProjectSelector;
