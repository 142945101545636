/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { View, Flex, ActionButton, Text } from "@adobe/react-spectrum";
import SearchBar from "../search/SearchBar";
import Recents from "./Recents";
import EditUserPreferencesButton from "./EditUserPreferencesButton";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useNavigate } from "react-router-dom";
import Shield from "@spectrum-icons/workflow/Shield";

const SubNavbar: React.FC = () => {
  const userSelectedProductFamily = useSelector(
    (state: RootState) => state.user.preferences?.product_families[0]?.name,
  );
  const navigate = useNavigate();

  return (
    <View
      colorVersion={6}
      backgroundColor="indigo-900"
      paddingX="size-400"
      paddingY="size-150"
    >
      <Flex direction="row" justifyContent="space-between">
        <View>
          <SearchBar />
        </View>
        <Flex gap="size-50">
          {/*TODO: REMOVE WHEN EXTERNAL TOOLS WDIGET FEATURE IS COMPLETED, OR WHEN THIS LINK NEEDS TO BE PUT SOMEWHERE ELSE*/}
          {userSelectedProductFamily === "Adobe Workfront" && (
            <>
              <ActionButton
                onPress={() => navigate("/workfront")}
                UNSAFE_className="analytics-track-me"
                data-analytics-link-name="Workfront Automation"
                data-analytics-view-name="Landing page"
                staticColor="white"
                isQuiet
              >
                <Shield />
                <Text>Workfront Automation</Text>
              </ActionButton>
              <ActionButton
                onPress={() => navigate("/workfront/create-defect")}
                UNSAFE_className="analytics-track-me"
                data-analytics-link-name="Workfront Automation: Create a Defect"
                data-analytics-view-name="Landing page"
                staticColor="white"
                isQuiet
              >
                <Shield />
                <Text>Workfront: Create a Defect</Text>
              </ActionButton>
            </>
          )}
          <Recents />
          <EditUserPreferencesButton />
        </Flex>
      </Flex>
    </View>
  );
};
export default SubNavbar;
