/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  useGetTicketByIdQuery,
  useGetTicketByFullInfoQuery,
  useRefreshTicketMutation,
  useDynamicsCollectDataMutation,
} from "../services/supportInsights";
import {
  setCasePayload,
  setInternalNotes,
  setTicketResource,
} from "../store/case/caseSlice";
import { RootState } from "../store/store";
import { handleRefresh } from "../utils/handleRefresh";
import { collectDynamicsData } from "../utils/collectDynamicsData";
import familyNameToCode from "../utils/familyNameToCode";
import { addToDataLayer } from "../utils/analytics";

const useCaseData = () => {
  const casePayload = useSelector((state: RootState) => state.case.casePayload);
  const caseIdState = casePayload?.caseId;
  const orgIdState = casePayload?.orgId;
  const rengaIdState = casePayload?.admin?.rengaId;
  const dispatch = useDispatch();
  const { caseId, orgId, rengaId } = useParams();
  const [, setIsDisabled] = useState(false);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [forceTicketDataReload, setForceTicketDataReload] = useState(false);
  const [forceRecollect, setForceRecollect] = useState(false);
  const [refreshError, setRefreshError] = useState({});
  const [doneLoading, setDoneLoading] = useState(false);

  const [refreshTicketData] = useRefreshTicketMutation();
  const productFamily = casePayload?.productFamilyName ?? "";
  const productFamilyCode = familyNameToCode(productFamily);

  const validCaseId = caseIdState || caseId || "";
  const validOrgId = orgIdState || orgId || "";
  const validRengaId = rengaIdState || rengaId || "";
  const shouldUseFullInfoQuery =
    validCaseId && validOrgId && validRengaId && !casePayload;
  const shouldUseByIdQuery =
    validCaseId && !casePayload && !shouldUseFullInfoQuery;

  const [dynamicsCollectData, { isLoading: dynamicsIsLoading }] =
    useDynamicsCollectDataMutation();

  const fullInfoQueryResult = useGetTicketByFullInfoQuery(
    { ticketId: validCaseId, orgId: validOrgId, rengaId: validRengaId },
    {
      skip: !shouldUseFullInfoQuery,
    },
  );

  const byIdQueryResult = useGetTicketByIdQuery(
    { ticketId: validCaseId },
    {
      skip: !shouldUseByIdQuery,
    },
  );
  const queryResult = shouldUseFullInfoQuery
    ? fullInfoQueryResult
    : byIdQueryResult;

  const { data, error, isLoading, isFetching } = queryResult;

  useEffect(() => {
    if (error !== undefined && "status" in error && error.status !== 200) {
      setForceTicketDataReload(true);
    }
  }, [error]);

  useEffect(() => {
    if (data?.data && !data.data.case_title) {
      setForceTicketDataReload(true);
    } else if (data?.data?.updated_at) {
      const currentDate = new Date();
      const ticketUpdatedAt = new Date(data?.data?.updated_at);
      const diffInTime = currentDate.getTime() - ticketUpdatedAt.getTime();
      const diffInDays = Math.round(diffInTime / (1000 * 3600 * 24));
      if (diffInDays > 1) {
        setForceTicketDataReload(true);
      } else {
        setDoneLoading(true);
      }
    } else {
      setDoneLoading(true);
    }
  }, [data]);

  useEffect(() => {
    if (forceTicketDataReload && !isRefreshing) {
      setIsRefreshing(true);
      handleRefresh({
        caseId: validCaseId,
        orgId: validOrgId,
        rengaId: validRengaId,
        productFamily: casePayload?.productFamilyName ?? "",
        refreshTicketData,
        setIsDisabled,
        dispatch,
      })
        .catch((error) => {
          setRefreshError(error);
        })
        .finally(() => {
          setIsRefreshing(false);
          setForceTicketDataReload(false);
          setForceRecollect(true);
          setDoneLoading(true);
        });
    }
  }, [forceTicketDataReload, casePayload]);

  useEffect(() => {
    if (casePayload !== undefined && forceRecollect) {
      collectDynamicsData({
        casePayload,
        dynamicsCollectData,
        productFamilyCode,
        dispatch,
        setIsDisabled,
        silent: true,
      });
    }
  }, [
    casePayload,
    dispatch,
    dynamicsCollectData,
    forceTicketDataReload,
    productFamilyCode,
  ]);

  useEffect(() => {
    if (!casePayload && !isLoading && !error && data?.data) {
      dispatch(setCasePayload(data.data.payload));
      addToDataLayer({
        caseInfo: {
          caseId: data.data.caseId,
          solution: data.data.case_solution,
          orgId: data.data.case_org_id,
        },
      });
      dispatch(setTicketResource(data.data));
      if (data.data.payload.internalNotes) {
        dispatch(setInternalNotes(data.data.payload.internalNotes));
      }
    }
  }, [
    caseId,
    caseIdState,
    casePayload,
    data,
    error,
    isLoading,
    dispatch,
    orgId,
    orgIdState,
    rengaId,
    rengaIdState,
    shouldUseFullInfoQuery,
  ]);

  return {
    casePayload,
    refreshError,
    isLoading,
    isFetching,
    caseId,
    caseResponseData: data,
    forceTicketDataReload,
    doneLoading,
  };
};

export default useCaseData;
