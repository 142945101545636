/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { useGetUserAssignedTicketsQuery } from "../../services/supportInsightsUserPersona";
import HomePageCardWrapper from "./HomePageCardWrapper";
import TicketTable from "./TicketTable";

const UserAssignedTicketsCard: React.FC = () => {
  const { data, isLoading, error } = useGetUserAssignedTicketsQuery();

  // Create a filtered response by removing any null tickets.
  const filteredResponse = data
    ? { ...data, data: data.data.filter((ticket) => ticket !== null) }
    : undefined;

  const tickets = filteredResponse?.data || [];
  const itemCount = data?.meta?.item_count;

  return (
    <HomePageCardWrapper
      isLoading={isLoading}
      error={error}
      data={tickets}
      title="My Cases"
      itemCount={itemCount}
      noDataTitle="There are no open cases assigned to you."
      errorMessage="There was an error fetching your assigned cases. Please try again later."
    >
      <TicketTable data={tickets} />
    </HomePageCardWrapper>
  );
};

export default UserAssignedTicketsCard;
