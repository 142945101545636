/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import HomePageCard from "./HomePageCard";
import { Flex } from "@adobe/react-spectrum";
import MessageComponent from "../common/MessageComponent";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import { SerializedError } from "@reduxjs/toolkit";

interface DataQueryCardProps {
  isLoading: boolean;
  error: FetchBaseQueryError | SerializedError | undefined;
  data: any[] | undefined;
  title: string;
  itemCount?: number;
  noDataTitle: string;
  noDataDescription?: string;
  errorMessage: string;
  children: React.ReactNode;
}

const HomePageCardWrapper: React.FC<DataQueryCardProps> = ({
  isLoading,
  error,
  data,
  title,
  itemCount,
  noDataTitle,
  noDataDescription,
  errorMessage,
  children,
}) => {
  if (isLoading) {
    return <HomePageCard isLoading />;
  }

  if (error) {
    return (
      <HomePageCard>
        <Flex justifyContent="center" alignItems="center" height="100%">
          <MessageComponent title="" isLoading={false}>
            {errorMessage}
          </MessageComponent>
        </Flex>
      </HomePageCard>
    );
  }

  if (data && data.length > 0) {
    return (
      <HomePageCard title={title} count={itemCount}>
        {children}
      </HomePageCard>
    );
  } else if (data && data.length === 0) {
    return (
      <HomePageCard title={title}>
        <Flex
          direction="column"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <p className="user-page-table-row-title">{noDataTitle}</p>
          {noDataDescription && (
            <p className="user-page-table-row-value">{noDataDescription}</p>
          )}
        </Flex>
      </HomePageCard>
    );
  }

  return null;
};

export default HomePageCardWrapper;
