/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect } from "react";
import { Picker, Item } from "@adobe/react-spectrum";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store/store";
import { setInstanceEnvironmentId } from "../../store/case/caseSlice";
import { useGetEnvironmentQuery } from "../../services/supportInsights";

interface EnvironmentSelectorProps {
  orgId: number;
  productFamilyId: number;
}

const EnvironmentSelector: React.FC<EnvironmentSelectorProps> = ({
  orgId,
  productFamilyId,
}) => {
  const dispatch = useDispatch();
  const environmentId = useSelector(
    (state: RootState) => state.case.instance.environmentId,
  );
  const projectId = useSelector(
    (state: RootState) => state.case.instance.projectId,
  );

  const { data, isLoading, isError } = useGetEnvironmentQuery(
    {
      orgId: orgId,
      productFamilyId: productFamilyId,
      projectId: projectId ?? "",
    },
    { skip: !projectId },
  );

  useEffect(() => {
    if (data && data.data.length === 0) {
      dispatch(setInstanceEnvironmentId(null));
    } else if (!data && !projectId && !isLoading) {
      dispatch(setInstanceEnvironmentId(null));
    }
  }, [data, projectId, isLoading, dispatch]);

  if (isLoading) {
    return (
      <Picker placeholder="Loading" label="Environment" isDisabled>
        <Item key="loading">Loading </Item>
      </Picker>
    );
  }

  if (data && data.data.length > 0 && !isError) {
    return (
      <Picker
        selectedKey={environmentId}
        placeholder="Select An Environment"
        label="Environment (Host)"
        width="250px"
        onSelectionChange={(selected) =>
          dispatch(setInstanceEnvironmentId(selected as number))
        }
      >
        {data.data.map((environment) => (
          <Item key={environment.id}>{environment.name}</Item>
        ))}
      </Picker>
    );
  }

  if (data && data.data.length === 0) {
    return (
      <Picker placeholder="No Environments" isDisabled label="Environment">
        <Item key="loading">No Environments </Item>
      </Picker>
    );
  }

  if (!data && !projectId && !isLoading) {
    return (
      <Picker
        placeholder="Select An Provisioned Instance First"
        isDisabled
        label="Environment (Host)"
      >
        <Item key="loading">No Project Selected </Item>
      </Picker>
    );
  }

  return null;
};

export default EnvironmentSelector;
