/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import HomePageCardTableRow from "./HomePageCardTableRow";
import { WatchlistOrgResponse } from "../../types/watchlist";
import TableRowKeyValuePair from "./TableRowKeyValuePair";

interface UserWatchlistTableProps {
  data: WatchlistOrgResponse;
}

const UserOrgWatchlistTable: React.FC<UserWatchlistTableProps> = ({ data }) => {
  return (
    <>
      {data.data.map((org) => (
        <HomePageCardTableRow>
          <TableRowKeyValuePair
            title="Org Id"
            value={org.item.code}
            elementWidth="50%"
          />
          <TableRowKeyValuePair
            title={"Org Name"}
            value={
              org.item.name !== org.item.code && org.item.name !== null
                ? org.item.name
                : "Unknown"
            }
            elementWidth={"50%"}
            valueType="navLink"
            linkHref={"/accounts/" + org.item.code}
          />
        </HomePageCardTableRow>
      ))}
    </>
  );
};

export default UserOrgWatchlistTable;
