/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import {
  ActionButton,
  Flex,
  Item,
  Link,
  TabList,
  TabPanels,
  Tabs,
  Text,
  View,
} from "@adobe/react-spectrum";
import Add from "@spectrum-icons/workflow/Add";
import DatapointsTable from "./DatapointsTable";
import FindingsTable from "./FindingsTable";
import "../../styles/main.scss";
import LastAnalysisBar from "./LastAnalysisBar";
import RefreshDataButton from "./RefreshDataButton";
import InstanceSelectors from "../multiInstanceSupport/InstanceSelectors";

const TabContainer: React.FC = () => {
  return (
    <Tabs aria-label="Augmented Analysis Tabs">
      <Flex>
        <TabList
          UNSAFE_className="analytics-tabs"
          flex="1 1 auto"
          minWidth="0px"
          alignSelf="end"
        >
          <Item key="datapoints" data-analytics-link-name="Data Points tab">
            Data Points
          </Item>
          <Item key="findings" data-analytics-link-name="Findings tab">
            Findings
          </Item>
        </TabList>
        <div
          style={{
            display: "flex",
            flex: "0 0 auto",
            borderBottom:
              "var(--spectrum-alias-border-size-thick) solid var(--spectrum-global-color-gray-300)",
            alignItems: "end",
            paddingBottom: "var(--spectrum-global-dimension-size-150)",
          }}
        >
          <InstanceSelectors />
          <RefreshDataButton></RefreshDataButton>
        </div>
      </Flex>
      <TabPanels marginTop="size-200">
        <Item key="datapoints">
          <Flex direction="row" justifyContent="end">
            <LastAnalysisBar />
            <Flex direction="row" justifyContent="end">
              <View paddingY="size-150">
                <Link
                  href="https://adobe.sharepoint.com/sites/DXSupportInsights/SitePages/CCF.aspx?csf=1&web=1&e=DcmkbP"
                  target="_blank"
                >
                  <ActionButton
                    UNSAFE_className="cursor_Hand analytics-track-me"
                    data-analytics-link-name="Add Data Point"
                    data-analytics-link-type="exit"
                    data-analytics-view-name="Data points"
                  >
                    <Add />
                    <Text>Add Data Point</Text>
                  </ActionButton>
                </Link>
              </View>
            </Flex>
          </Flex>
          <DatapointsTable />
        </Item>
        <Item key="findings">
          <Flex direction="row" justifyContent="end">
            <LastAnalysisBar />
            <Flex direction="row" justifyContent="end">
              <View paddingY="size-150">
                <Link
                  href="https://adobe.sharepoint.com/sites/DXSupportInsights/SitePages/CCF.aspx?csf=1&web=1&e=DcmkbP"
                  target="_blank"
                >
                  <ActionButton
                    UNSAFE_className="cursor_Hand analytics-track-me"
                    data-analytics-link-name="Add finding"
                    data-analytics-link-type="exit"
                    data-analytics-view-name="Findings"
                  >
                    <Add />
                    <Text>Add finding</Text>
                  </ActionButton>
                </Link>
              </View>
            </Flex>
          </Flex>
          <FindingsTable />
        </Item>
      </TabPanels>
    </Tabs>
  );
};

export default TabContainer;
