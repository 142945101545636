/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { TicketResource } from "./case";

export interface WatchlistTicketItem {
  item_id: number;
  item_type: string;
  item: TicketResource;
}

export interface WatchlistOrgItem {
  item_id: number;
  item_type: string;
  item: {
    name: string;
    code: string;
  };
}

export interface TicketItemResponse {
  data: WatchlistTicketItem[];
  meta: {
    item_count: number;
    watchlist_item_type: string;
  };
}

export interface OrgItemResponse {
  data: WatchlistOrgItem[];
  meta: {
    item_count: number;
    watchlist_item_type: string;
  };
}

export interface MutationArgument {
  payload: {
    itemId: number;
    itemType: string;
  };
}

export interface MutationResponse {
  data: [];
  meta: {
    message: string;
    id?: number;
  };
}

export interface CheckItemResponse {
  data: {
    isItemWatchlisted: boolean;
  };
  meta: [];
}

export interface CheckItemArgument {
  itemId: number;
  itemType: string;
}

export interface WatchlistTicketResponse {
  data: WatchlistTicketItem[];
  meta: {
    item_count: number;
    watchlist_item_type: "ticket";
  };
}

export interface WatchlistOrgResponse {
  data: WatchlistOrgItem[];
  meta: {
    item_count: number;
    watchlist_item_type: "org";
  };
}

export type GetWatchlistResponse =
  | WatchlistTicketResponse
  | WatchlistOrgResponse;

//Custom Type Guards for Type Narrowing
export function isWatchlistTicketResponse(
  data: GetWatchlistResponse,
): data is WatchlistTicketResponse {
  return data.meta.watchlist_item_type === "ticket";
}

export function isWatchlistOrgResponse(
  data: GetWatchlistResponse,
): data is WatchlistOrgResponse {
  return data.meta.watchlist_item_type === "org";
}
