/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

export interface AiPrompt {
  label: string;
  prompt: string | null;
}

export const promptMatrix = {
  "account-case-trends": {
    label: "Account Case Trends",
    prompt:
      "Review and summarize customer support tickets to identify patterns, recurring issues, and technical trends. Dynamically group issues by inferred domain areas and provide insights into frequency, priority, and actionable improvements. Order historical issue groups by the number of issues in each group (highest to lowest). Conclude with a high-level analysis categorized into Technical Trends, Customer Direction, and Organization Trends.\n" +
      "\n" +
      "Steps\n" +
      "1. Review Support Tickets\n" +
      "Analyze customer support tickets to capture:\n" +
      "Core context: Include a brief description of the issue and the functionality impacted.\n" +
      "Resolution steps: Document the actions taken to resolve the issue. Specify who resolved the issue, such as team members, departments, or external resources. If unresolved, provide details on why and any follow-up plans to address these cases. Highlight unresolved tickets that resulted in negative feedback.\n" +
      "Underlying causes: Identify contributing factors or triggers.\n" +
      "2. Dynamically Group Tickets by Domain\n" +
      "Infer domain areas dynamically from the nature of the issues, such as Infrastructure, Security, Application, or others.\n" +
      "Order groups by the number of issues in each domain area, from highest to lowest.\n" +
      "Consolidate related tickets under each domain area and present them in markdown format:\n" +
      "- Issue: Description of the problem.\n" +
      "- Priority: Indicate severity (e.g., P1 - Critical, P3 - Important).\n" +
      "- Contact Reason: Highlight why the customer contacted support.\n" +
      "- Conditions: Describe any triggers, systemic causes, or environmental factors.\n" +
      "- Customer Escalated: Indicate whether the issue was escalated by the customer (Yes/No).\n" +
      "- Resolution Steps: Include who resolved the issue, detailed steps taken, and follow-up actions for unresolved tickets.\n" +
      "3. Identify Technical Trends\n" +
      "Highlight trends and patterns across domain areas:\n" +
      "Categories of issues: Identify recurring problem areas (e.g., Authentication, Networking).\n" +
      "Triggers: Highlight common events or factors (e.g., Peak usage, configuration errors).\n" +
      "Impacts: Identify the systems, components, or processes frequently affected.\n" +
      "Differentiate between:\n" +
      "Systemic risks: Recurring problems indicative of structural or design flaws.\n" +
      "Isolated occurrences: One-off issues unlikely to recur.\n" +
      "4. Provide High-Level Technical Analysis\n" +
      "Summarize the findings by grouping them into:\n" +
      "Technical Trends:\n" +
      "Identify recurring issues and their systemic impact.\n" +
      "Highlight affected systems and any observed patterns.\n" +
      "Customer Direction:\n" +
      "Provide actionable recommendations for customers, such as automation, resource allocation, or process improvements.\n" +
      "Organization Trends:\n" +
      "Present strategic opportunities for improving scalability, monitoring, or process standardization.\n" +
      "Output Format\n" +
      "Historical Issues (<X> Cases)\n" +
      "Order the groups by the number of issues in each domain area (highest to lowest):\n" +
      "\n" +
      "<Domain Area> (<X> Cases)\n" +
      "\n" +
      "markdown\n" +
      "- **Issue**: [CASE ID][Brief description of the issue]  \n" +
      "  **Priority**: P[X] - [Priority Level]  \n" +
      "  **Contact Reason**: [Why the customer contacted support]  \n" +
      "  **Conditions**: [Triggers or contributing factors]  \n" +
      "  **Customer Escalated**: [Yes/No. Take from 'isEscalated' field]  \n" +
      "  **Resolution Steps**:\n" +
      "  - **Step 1**: [Initial action taken to address the issue].  \n" +
      "  - **Step 2**: [Further investigation or analysis performed].  \n" +
      "  - **Step 3**: [Actions taken to resolve the issue, including specific technical steps, tools, or methods used].  \n" +
      "  - **Step 4**: [Validation or confirmation that the issue was resolved, including testing or monitoring].  \n" +
      "  - **Unresolved Follow-Up**: [If unresolved, include next steps planned, reassignment details, or reasons for delay].  \n" +
      "  - **Resolved By**: [Name and role of the person or team responsible for resolving the issue].   \n" +
      "Overall Trend Summary\n" +
      "Technical Trends:\n" +
      "Summarize recurring issues across all domain areas and their systemic impact. Identify common triggers, affected systems, and patterns.\n" +
      "\n" +
      "Customer Direction:\n" +
      "Provide actionable recommendations tailored to customer needs. Suggest process improvements, automation, or system changes.\n" +
      "\n" +
      "Organization Trends:\n" +
      "Present organization-level insights, focusing on scalability, monitoring, and automation opportunities.\n" +
      "\n" +
      "Example  \n" +
      "Historical Issues (5 Cases)  \n" +
      "## Infrastructure (3 Cases)\n" +
      "\n" +
      "- **Issue**: [E-001188043] Redis Upgrade on Production  \n" +
      "  **Priority**: P3 - Important  \n" +
      "  **Contact Reason**: Infrastructure Change Request  \n" +
      "  **Conditions**: Upgrade scheduled for Friday evening to minimize impact.  \n" +
      "  **Customer Escalated**: No  \n" +
      "  **Resolution Steps**:  \n" +
      "    - **Step 1**: Identified the need for Redis upgrade during security compliance review.  \n" +
      "    - **Step 2**: Scheduled upgrade during low usage time to minimize impact.  \n" +
      "    - **Step 3**: Conducted the upgrade to Redis 7.2.  \n" +
      "    - **Step 4**: Verified success via post-upgrade monitoring tools.  \n" +
      "    - **Unresolved Follow-Up**: None, issue fully resolved.  \n" +
      "    - **Resolved By**: DevOps team lead (John Doe).  \n" +
      "\n" +
      "- **Issue**: [E-001188022] Install New Certificate for Staging Site  \n" +
      "  **Priority**: P3 - Important  \n" +
      "  **Contact Reason**: SSL Certificate Installation  \n" +
      "  **Conditions**: Lack of sudo access prevented direct installation.  \n" +
      "  **Customer Escalated**: Yes  \n" +
      "  **Resolution Steps**:  \n" +
      "    - **Step 1**: Customer requested certificate installation for staging.  \n" +
      "    - **Step 2**: Advised setting certificate paths in `php.ini` or using `CURLOPT_CAPATH`.  \n" +
      "    - **Step 3**: Attempted direct installation but faced sudo access limitations.  \n" +
      "    - **Unresolved Follow-Up**: None, issue fully resolved.  \n" +
      "    - **Resolved By**: IT Support Specialist (Jane Smith).  \n" +
      "\n" +
      "- **Issue**: [E-001232098] Redis Upgrade for Staging  \n" +
      "  **Priority**: P3 - Important  \n" +
      "  **Contact Reason**: Security Compliance  \n" +
      "  **Conditions**: Security scan failures due to outdated Redis version.  \n" +
      "  **Customer Escalated**: Yes  \n" +
      "  **Resolution Steps**:  \n" +
      "    - **Step 1**: Coordinated upgrade to Redis 7.2 for staging environment.  \n" +
      "    - **Step 2**: Identified resource constraints delaying completion.  \n" +
      "    - **Unresolved Follow-Up**: Remains unresolved; follow-up scheduled for next sprint.  \n" +
      "    - **Resolved By**: Assigned to the DevOps team (Unresolved).  \n" +
      "\n" +
      "---\n" +
      "\n" +
      "## Application (1 Case)\n" +
      "\n" +
      "- **Issue**: [E-001562008] Cron Jobs Stopped Working  \n" +
      "  **Priority**: P2 - Urgent  \n" +
      "  **Contact Reason**: Core Application (Other)  \n" +
      "  **Conditions**: Deployment failure due to database schema mismatch.  \n" +
      "  **Escalated**: Yes  " +
      "  **Resolution Steps**:  \n" +
      "    - **Step 1**: Investigated cron job stoppage via system logs.  \n" +
      "    - **Step 2**: Discovered deployment errors caused by a database schema mismatch.  \n" +
      "    - **Step 3**: Restarted cron jobs and attempted resolution.  \n" +
      "    - **Unresolved Follow-Up**: Remains unresolved. Negative feedback received due to delays. Follow-up meeting scheduled to reassess priorities.  \n" +
      "    - **Resolved By**: Assigned to Backend Team (Unresolved).  \n" +
      "Overall Trend Summary\n" +
      "Technical Trends:\n" +
      "\n" +
      "Infrastructure: Frequent Redis upgrades and SSL certificate issues highlight the need for automated maintenance.\n" +
      "Security: False positives in scans emphasize the need for updated tools.\n" +
      "Application: Cron job failures highlight process gaps during schema deployments.\n" +
      "Customer Direction:\n" +
      "\n" +
      "Automate processes like Redis upgrades and SSL certificate installations.\n" +
      "Upgrade scanning tools to minimize false positives.\n" +
      "Improve deployment validation processes to avoid schema mismatches.\n" +
      "Organization Trends:\n" +
      "\n" +
      "Improve scalability through dynamic resource allocation during peak usage.\n" +
      "Standardize processes for deployment and infrastructure updates.\n" +
      "Enhance monitoring systems to detect systemic risks early.",
  },
  "investigate-summary-commerce-project": {
    label: "Project Summary",
    prompt:
      "Provide instance summary. Use the following format for your response:\n" +
      "## Project Summary\n" +
      "- **Instance URL:** (Project URL)\n" +
      "- **Environment:** Production\n" +
      "- **Adobe Commerce:** (AC value)\n" +
      "- **HIPAA Compliance:** (value)\n" +
      "- **B2B Version:** (value)\n" +
      "- **ECE Version:** (value)\n" +
      "- **Mage ID:** (value)\n" +
      "- **Cluster Type:** (value)\n" +
      "### Services:\n" +
      "- **PHP:** (value) if (PHP EOL Date) print '*Expired on (PHP EOL Date)*'\n" +
      "- **MySQL:** (value) if (MySQL EOL Date) print '*Expired on (MySQL EOL Date)*'\n" +
      "- **Redis:** (value) if (Redis EOL Date) print '*Expired on (Redis EOL Date)*'\n" +
      "- **Elasticsearch:** (value) if (Elasticsearch EOL Date) print '*Expired on (Elasticsearch EOL Date)*'\n" +
      "### Commerce SaaS Services:\n" +
      "- **Live Search:** (version) \n" +
      "- **Catalog Service:** (version) \n" +
      "- **Product Recommendations:** (version) \n" +
      "- **Payment Services:** (version) \n",
  },
  "investigate-commerce-db-status": {
    label: "DB Status Summary",
    prompt:
      "Perform an \"InnoDB Status\" analysis and provide a conclusion. Make a detailed analysis of the 'LATEST FOREIGN KEY ERROR' and 'LATEST DETECTED DEADLOCK' (show queries). Perform a brief analysis of other sections.",
  },
  "investigate-summary-quick": {
    label: "Quick Summary",
    prompt:
      "Summarize the customer case in the following format:\n" +
      "#### Summarized Description:\n" +
      "text\n" +
      "- **Customer Impact:**\n" +
      "- **Customer Communication:**\n" +
      "- **Sentiment Analysis:**\n" +
      "- **Steps Taken:**\n" +
      "- **Status and Resolution:**\n" +
      "- **Final Outcome:**\n" +
      "Ensure that each item is a block of Markdown text and includes the names of relevant individuals.",
  },
  "investigate-summary-detailed": {
    label: "Detailed Summary",
    prompt:
      "Provide a detailed summary of the customer case, and include relevant links.",
  },
  "investigate-summary-soap": {
    label: "SOAP Summary",
    prompt:
      "Summarize the customer case in the following format:\n" +
      "- **Scope:** (Define the issue/question on the ticket, as specific as possible)\n" +
      "- **Objective:** (Define the expected outcome)\n" +
      "- **Actions:** (Define the specific actions performed by the support engineer and customer)\n" +
      "- **Plan:** (Describe future actions to be done by the support engineer and customer)\n" +
      "- **Business Impact:** (Describe business impact)\n" +
      "- **Current Work Around:** (Describe current work around)\n" +
      "- **Next Steps:** (Describe next steps)\n" +
      "Ensure that each item includes the names of relevant individuals.",
  },
  "investigate-summary-cadence": {
    label: "Cadence Summary",
    prompt:
      "# Detailed Cadence Summary Table Prompt\n" +
      "\n" +
      "## Task\n" +
      "\n" +
      "Create a detailed cadence summary table for a customer case, capturing the sequence of responses, timestamps, delays, and overall **Customer Support Average Response Time**. Ensure accurate identification of the **Longest delay** and **Shortest delay**.\n" +
      "\n" +
      "---\n" +
      "\n" +
      "## Steps\n" +
      "\n" +
      "### 1. Briefly describe the purpose of the Cadence Summary table (e.g. Based on the provided customer case, here is the cadence summary indicating who responded and when, along with where the delay in the response occurred, and time delay between each response)\n" +
      "### 2. Organize Chronologically\n" +
      "- Arrange all responses by their **Date and Time (UTC)** in chronological order.\n" +
      "- Use strict formatting for timestamps: `yyyy-MM-dd HH:mm:ss`.\n" +
      "\n" +
      "### 3. Calculate Time Delay\n" +
      "- Compute the absolute difference in time between each response and the one that immediately preceded it.\n" +
      '- Use the format "Xd Xh Xm" for the delay:\n' +
      "  - **X** = Value (e.g., 1, 2, 3).\n" +
      "  - **d** = Days.\n" +
      "  - **h** = Hours.\n" +
      "  - **m** = Minutes.\n" +
      '  - Omit any zero values for better readability (e.g., "1d 4h" instead of "1d 4h 0m").\n' +
      "\n" +
      "### 4. Calculate Customer Support **Average Response Time**\n" +
      "- Identify all responses made by customer support roles (e.g., Agent, Supervisor).\n" +
      "- Calculate the average time it took for customer support to respond after the preceding message.\n" +
      '- Present the **Customer Support Average Response Time** in the format "Xd Xh Xm."\n' +
      "\n" +
      "### 5. Identify Longest and Shortest Delays\n" +
      "- Compare all calculated delays (excluding the first response with `-` for delay).\n" +
      "- Determine and report:\n" +
      "  - **Longest delay**: The maximum delay in the process.\n" +
      "  - **Shortest delay**: The minimum delay in the process.\n" +
      "\n" +
      "---\n" +
      "\n" +
      "## Output Format\n" +
      "\n" +
      "Provide the summarized information in a markdown-formatted table with the following columns:\n" +
      "\n" +
      "- **Responder**: Name or identifier of the responder.\n" +
      "- **Role**: Indicate the role of the responder (e.g., customer, support agent).\n" +
      "- **Date and Time (UTC)**: Record the time of the response, formatted as `yyyy-MM-dd HH:mm:ss`.\n" +
      "- **Summary**: Briefly describe the context or purpose of the response.\n" +
      "- **Time Delay (Xd Xh Xm)**: Accurately calculate and present the delay since the last response.\n" +
      "\n" +
      "### Example Table\n" +
      "\n" +
      "| **Responder** | **Role**  | **Date and Time (UTC)** | **Summary**                | **Time Delay (Xd Xh Xm)** |\n" +
      "|---------------|-----------|-------------------------|----------------------------|---------------------------|\n" +
      "| Agent         | Support   | 2023-04-15 12:30:45    | Asked for more details     | -                         |\n" +
      "| Customer      | Client    | 2023-04-15 15:47:11    | Provided information       | 0d 3h 16m                 |\n" +
      "| Supervisor    | Manager   | 2023-04-16 09:22:31    | Clarified next steps       | 0d 17h 35m               |\n" +
      "| Customer      | Client    | 2023-04-16 12:10:00    | Confirmed receipt          | 0d 2h 48m                 |\n" +
      "\n" +
      "---\n" +
      "\n" +
      "## Observations\n" +
      "\n" +
      "After generating the table, summarize the following:\n" +
      "\n" +
      "### 1. Average Response Time, Longest and Shortest Delays\n" +
      "- **Average Response Time**: Calculate and report only the final average time the customer waited for a response from support roles (e.g., Agent, Supervisor), omitting detailed calculations.\n" +
      "- **Longest Delay**: Identify and report the maximum delay, including the responders involved.\n" +
      "- **Shortest Delay**: Identify and report the minimum delay, including the responders involved.\n" +
      "\n" +
      "### 2. **Average Response Time**\n" +
      "- Summarize the average response time of the support team (e.g., Agent, Supervisor).\n" +
      "- Include only the delays directly associated with responses from support roles.\n" +
      "\n" +
      "### Example Observations\n" +
      "\n" +
      "- The **Average Response Time** was 8 hours and 25 minutes.\n" +
      "- The **Longest delay** was 17 hours and 35 minutes, occurring between the support agent and supervisor responses.\n" +
      "- The **Shortest delay** was 2 hours and 48 minutes, occurring between the supervisor's reply and the customer's confirmation.\n" +
      "\n" +
      "---\n" +
      "\n" +
      "## Notes\n" +
      "\n" +
      "- Always calculate delays consistently for all rows.\n" +
      "- If delays are equal, mention all ties or clarify tie-breaking logic.\n" +
      "- Clearly distinguish delays to indicate if they result from internal team response times or customer wait times.\n" +
      '- Use the specific delay format ("Xd Xh Xm") for uniformity and clarity.\n',
  },
  "suggest-resolution": {
    label: "Suggest Resolution",
    prompt: null,
  },
  "other-knowledge-center": {
    label: "Knowledge Center",
    prompt: null,
  },
  "investigate-custom-prompt": {
    label: "Custom Prompt",
    prompt: null,
  },
  "non-cso-rca": {
    label: "Non-CSO RCA",
    prompt:
      "You are a support engineer that is in charge of making RCA statement describing critical issues (with the software {product}). These issues are sometimes referred to internally as CSOs You will be given details taken from an customer case and" +
      " Jira tickets (take into account Jira tickets comments) and you need to summarize the issue in a way that would make sense to an end user of {product}. Avoid using technical jargon or references to the names of any back end services. The customer statement should include a description of the issue, impacts, timeframe it occurred, a high level description of the resolution, and if applicable any actions required on the customers end. Try not to be overly apologetic and focus mostly on the facts (what happened, why, the scope of the issue, what we are doing going forward, when it happened, etc.). The end result will be a document sent out to customers to describe the issue\n" +
      "Everything below is a style guide for creating RCA statements. Please follow all of the suggestions/formats below\n" +
      "DOs and DONTs\n" +
      " DOs\n" +
      "	DO write with empathy and human tone to avoid using canned responses and sounding unsympathetic.\n" +
      "	DO review grammar and punctuation for correctness.\n" +
      "	DO keep it simple, succinct, and precise.\n" +
      "	DO clearly state the scope (e.g. single data center, all customers, etc.).\n" +
      "	DO focus on customer impact.\n" +
      "	DO provide workarounds when possible.\n" +
      "	DO get formal/written approval by Customer Support Leadership (and get CSO owner input if possible). \n" +
      "	DO run the content of the communication by your legal representative.\n" +
      "	DO write as if the world is reading.\n" +
      "	DO state the facts and acknowledge the issue.\n" +
      "	Tell the customers what happened without speculation. We want to avoid having to re-explain or qualify something we said initially. \n" +
      "	DO write in a way that any customer, at any technical level, can understand.\n" +
      "DONTs\n" +
      "	DON'T say technology names (e.g. network vs. AVI).\n" +
      '	DON\'T use the term "outage", instead, use "service disruption".\n' +
      "	DONT use the term human error.\n" +
      "	DONT use slang.\n" +
      "	DON'T list customer names or provide details for one customer to another customer.\n" +
      "	DON'T use specific technical jargon.\n" +
      "	DON'T state that Adobe has disciplined an employee.\n" +
      "	DON'T admit we did something wrong, or make any reference to SLA, or say something that suggests that an SLA has been breached, or subjects ourselves to contractual liability/warranty claims, e.g. creates additional financial risk/exposure to Adobe.\n" +
      "	DON'T say something factually incorrect (we have a fix when we dont, or vice versa, we say we have a problem and then go back and say we didnt have an issues). \n" +
      "	DON'T promise/commit to do more than we actually plan to do.\n" +
      "	Do not commit to do x/y/z in response to an issue unless we are sure we have the executive support and resources to in fact complete x/y/z.\n" +
      "	The same goes for time commitments. Do not commit to a certain time unless you are ~100% sure we will meet that time. Instead, opt for currently targeting type language, but still be confident in those estimates.\n" +
      "	Specifically, for External Communications:\n" +
      "	DON'T set resolution timing expectations or set expectations in general. The only exception is offering the next communication update if you feel that this would improve the customer's experience.\n" +
      "Common Terms\n" +
      "	Use may or could to make the sentence less definitive. \n" +
      "	Example: During the impacted timeframe, a subset of customers may have experienced delays in list imports as well as smart lists returning inaccurate results. In addition, smart campaigns could have failed to initiate.\n" +
      "	Use reinitialize rather than restart or reset.	Example: Our team reinitialized the server cluster to restore service and resume activity processing.\n" +
      "	Use impacted and affected.\n" +
      "    	Example: During the impacted timeframe, customers may have noticed delays in trigger campaigns as well as smart lists returning inaccurate data. Batch campaigns were not affected by this issue.\n" +
      "	Use intermittent to describe the issue when it is sporadic or inconsistent in nature.\n" +
      "	Example: During the impacted timeframe, customers may have experienced intermittent login errors when attempting to access their instances.\n" +
      "	Use subset when the issue did not affect all customers or services.\n" +
      "	Example: This is a RCA Statement for the Service Degradation that affected a subset of customers in our Ashburn data center on October 5, 2020.\n" +
      "	Avoid using the words down or outage. Instead, use unavailable, inaccessible, or unreachable to describe how an issue affected services/systems.\n" +
      "	Example: An expired license caused several network storage devices to become unavailable.\n" +
      "	Avoid using the word problem.\n" +
      "Non-CSO RCA Guidelines\n" +
      "a. Non-CSO RCA Template\n" +
      "b. Section Details\n" +
      "	#### Case Summary\n" +
      "	Overarching description of the incident (i.e., {product}  Service Degradation or Adobe Experience Manager Reporting Disruption)\n" +
      "	#### Overview\n" +
      "	The overview paragraph has three core components.\n" +
      "	The opening sentence introduces the incident and when it occurred. \n" +
      "	Only one sentence is needed.\n" +
      "	Use the same term for the problem as used in the Summary Title (disruption/issue/degradation). \n" +
      "	Examples:\n" +
      "	This is the final RCA Statement for the service disruption that affected a subset of customers in our San Jose data center on October 16, 2021.\n" +
      "	This is the final RCA Statement for the service degradation that affected customers in our London data center beginning on September 20, 2021.\n" +
      "	The second sentence should include a description of the customer impact.\n" +
      "	The description of symptoms should be short and concise. Keep it to one to two sentences. \n" +
      "	Examples: \n" +
      "	The Adobe {product} system hosting your subscription experienced a service disruption that impacted all web services.\n" +
      "	The Adobe {product} system hosting your subscription may have encountered an issue that impacted batch campaign processing.\n" +
      "	The final sentence should confirm that the issue has been resolved including the end date, if applicable. \n" +
      "	Examples: \n" +
      "	We identified and mitigated the source of the disruption, and this issue was resolved on December 20, 2021.\n" +
      "	We identified and mitigated the source of the degradation, and the issue has been resolved.\n" +
      "	#### Impact Window\n" +
      "	Format:\n" +
      "	Month Day, Year, Start Time  End Time\n" +
      "	Guidelines:\n" +
      "	Do not use th or nd after the day number. Just give the number alone.\n" +
      "	Use a    dash to indicate the duration between start and end timestamps, not to, through or until. \n" +
      "	Use Coordinated Universal Time (UTC).\n" +
      "	Example:\n" +
      "December 19, 2021, 11:24 UTC  11:49 UTC\n" +
      "	#### Impact Duration\n" +
      "	Give the number of days, hours, and minutes between the start and end times.\n" +
      "	Example:\n" +
      "5 Days, 15 Hours, 30 Minutes\n" +
      "	Service(s) Affected\n" +
      "	Provide a short description of the services that were affected. Keep descriptions high-level and be sure to list the main symptoms that the majority of customers could have experienced. If there is a long list of symptoms, group them into general descriptions. After listing what services were affected, describe what was still operating as expected.\n" +
      "	If all services were affected, that should be stated.\n" +
      "	Example:\n" +
      "During the impacted timeframe, access to all Adobe {product} web services may have been disrupted, including the serving of interactive logins, forms, and landing pages. While these services were impacted, campaign processing and all other back-end systems continued to function as expected.\n" +
      "	#### What Happened & Why\n" +
      "	This section tells the story to the customer including what caused the issue and how our teams remediated it. The cause statement is the main purpose of the document but is one of the shortest sections. Descriptions should be short, limited to a few sentences. Descriptions of the problem and symptoms can go into detail as needed, but most times the cause is very simple. Do not speculate; only list the facts. Write so that customers, at any technical level, can easily understand the impact. The remediation steps should describe what we did to fix the immediate issue.\n" +
      "	Resolution:\n" +
      "* This section should never be more than three sentences.\n" +
      "	Example:\n" +
      "Due to a misconfiguration, the memory capacity on the primary database was lower than the standard for other databases. This caused the database to reach the maximum number of available connections and prevented new connections from being processed. A replication delay prevented the team from immediately completing a primary switch, which prolonged the time to resolution.\n" +
      "The Adobe {product} throttled queries on the primary database to accelerate the rate of replication, then performed the primary database switch to restore service.\n" +
      "	#### Plans to Prevent Reoccurrence\n" +
      "	This section should outline:\n" +
      "	What is being done to maintain system stability in the short term?\n" +
      "	What changes are being made to prevent future occurrences (if available)? And, again, avoid over-committing; describe only what we are certain will be done and have executive support to ensure. \n" +
      "	Example:\n" +
      "	Adobe is taking the following steps to help prevent this event from reoccurring and to mitigate future potential impact:\n" +
      "	Increasing memory capacity on the affected database to ensure it meets the configuration standard.\n" +
      "	Updating the query execution logic to enhance database performance.\n",
  },
  "fts-note": {
    label: "FTS Handover Notes",
    prompt:
      "Summarize the customer case in following format:\n" +
      "- **Initial Support Engineers:** Name from each Geo [NA EST, NA PST, APAC, EMEA] in order of case assignment\n" +
      "- **Customer Name:**\n" +
      "- **Org In-Region Support:** region [AMER/APAC/EMEA] - country\n" +
      "- **Ticket Number:**\n" +
      "- **Escalation Type:** [DMA/CritSit/Transactional/Other]\n" +
      "- **Current Business Impact:** (To the customer and to Adobe)\n" +
      "- **Problem Description:**\n" +
      "- **Objective:**\n" +
      '- **Support Manager Approval Note:** ["Yes, see pinned note." / "No"]\n' +
      "- **24/7 Customer Contact:** (Name and Phone)\n" +
      "- **IMS Org ID:**\n" +
      "- **Instance URL:** Include full URL\n" +
      "- **Associated JIRA(s):** Include each full Ticket URL (External Ticket) from the case\n" +
      "- **Associated CSO, War Room, Situation Room, Slack Channel Links:**\n" +
      "- **Investigation Steps:** In chronological order by date including indentation/number order/bullet characters where applicable.\n" +
      "- **Adobe Documentation Reference** (Including full links)\n" +
      "- **Does the issue persist? [Yes/No]**\n" +
      "- **Any communication frequency requirements?**\n" +
      "- **Any additional expectations set?**\n" +
      "- **Currently Pending On:** [Customer/Support/Engineering/TechOps]\n" +
      "- **Next Steps for the Customer]:**  in order of priority, including indentation/number order/bullet characters where applicable.\n" +
      "- **Next Stepsfor Adobe [Support/Engineering/TechOps]:** in order of priority, including indentation/number order/bullet characters where applicable.\n" +
      "Ensure that each item is a block of Markdown text.\n",
  },
} satisfies Record<string, AiPrompt>;

export type PromptMatrixKey = keyof typeof promptMatrix;

export class PromptsManager {
  constructor(private readonly productFamily: string) {}

  public static readonly suggestionsKeys: PromptMatrixKey[] = [
    "investigate-summary-quick",
    "investigate-summary-detailed",
    "investigate-summary-soap",
    "investigate-summary-cadence",
    "account-case-trends",
  ];

  investigatingKeys(): PromptMatrixKey[] {
    const investigatingKeys: PromptMatrixKey[] = [
      ...PromptsManager.suggestionsKeys,
    ];

    if (this.productFamily === "Commerce") {
      investigatingKeys.push("investigate-summary-commerce-project");
      investigatingKeys.push("investigate-commerce-db-status");
    }

    return investigatingKeys;
  }

  wrappingUpKeys(): PromptMatrixKey[] {
    return ["non-cso-rca"];
  }

  suggestionsKeys(): PromptMatrixKey[] {
    const suggestions = [...PromptsManager.suggestionsKeys];

    if (this.productFamily === "Commerce") {
      suggestions.unshift("investigate-summary-commerce-project");
      suggestions.push("investigate-commerce-db-status");
    }
    suggestions.push("non-cso-rca");

    return suggestions;
  }
}
