/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect, useState } from "react";
import MessageComponent from "./MessageComponent";
import useCaseData from "../../hooks/useCaseData";
import { ToastQueue } from "@react-spectrum/toast";
import { CaseResponse } from "../../types/case";
import familyNameToCode from "../../utils/familyNameToCode";
import { addRecentCase } from "../../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { resetCase } from "../../store/case/caseSlice";
import type { RootState } from "../../store/store";

interface PageWrapperProps extends React.PropsWithChildren<{}> {
  pageName: string;
}

const defaultErrorMessage =
  "Something went wrong, please verify your case information and refresh the page.";

const PageWrapper: React.FC<PageWrapperProps> = ({ children }) => {
  const {
    casePayload,
    refreshError,
    isLoading,
    isFetching,
    caseId,
    caseResponseData,
    forceTicketDataReload,
    doneLoading,
  } = useCaseData();
  const dispatch = useDispatch();

  if (
    casePayload !== undefined &&
    caseId !== undefined &&
    casePayload.caseId !== caseId
  ) {
    dispatch(resetCase());
  }

  useEffect(() => {
    if (caseId !== undefined) {
      const recentCase = {
        caseId: caseId,
        url: window.location.href,
      };
      dispatch(addRecentCase(recentCase));
    }
  }, [caseId, dispatch]);

  const productFamily = casePayload?.productFamilyName ?? "";
  const productFamilyCode = familyNameToCode(productFamily);
  const productFamilyList = useSelector(
    (state: RootState) => state.user.productFamilies,
  );
  const isSupported = productFamilyList.find(
    (productFamily) => productFamily.code === productFamilyCode,
  );

  const [publicMessage, setPublicMessage] = useState<string | null>(null);

  useEffect(() => {
    if (doneLoading) {
      if (refreshError && "data" in refreshError) {
        const response = refreshError.data as CaseResponse;
        const message = response?.meta?.errors?.publicMessage ?? "";
        if (message) {
          setPublicMessage(message);
          ToastQueue.negative(message, { timeout: 5000 });
        }
      }
      if (caseResponseData && "meta" in caseResponseData) {
        const message = caseResponseData.meta?.errors?.publicMessage;
        if (message) {
          setPublicMessage(message);
          ToastQueue.negative(message, { timeout: 5000 });
        }
      }
    }
  }, [refreshError, caseResponseData, doneLoading]);

  if (isLoading || forceTicketDataReload || isFetching) {
    return (
      <MessageComponent title={"Oasis is Loading"} isLoading={true}>
        We are fetching your Support Insights for case:{" "}
        <strong>{caseId}</strong>.
      </MessageComponent>
    );
  }

  if (casePayload && isSupported) {
    return <>{children}</>;
  }

  if (!casePayload || refreshError) {
    return (
      <MessageComponent title="Case unavailable">
        The case <strong>{caseId}</strong> you are searching details for is not
        available.
      </MessageComponent>
    );
  }

  if (!isSupported && casePayload.productFamilyName) {
    return (
      <MessageComponent title="Product not supported">
        The case <strong>{caseId}</strong> belongs to the{" "}
        <strong>{productFamily}</strong> product family. Only cases under Adobe
        DX are supported.
      </MessageComponent>
    );
  }

  return (
    <MessageComponent title="Something went wrong">
      {publicMessage ? publicMessage : defaultErrorMessage}
    </MessageComponent>
  );
};

export default PageWrapper;
