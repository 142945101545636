/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect, useState } from "react";
import { Flex } from "@adobe/react-spectrum";
import ProjectSelector from "./ProjectSelector";
import EnvironmentSelector from "./EnvironmentSelector";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useGetOrganizationQuery } from "../../services/supportInsights";
import familyNameToCode from "../../utils/familyNameToCode";

const InstanceSelectors = () => {
  const ticketProductFamily = useSelector(
    (state: RootState) => state.case.casePayload?.productFamilyName,
  );
  const familyNameCode = familyNameToCode(ticketProductFamily ?? "");
  const ticketOrgId = useSelector(
    (state: RootState) => state.case.casePayload?.orgId,
  );
  const productFamilyList = useSelector(
    (state: RootState) => state.user.productFamilies,
  );
  const [productFamilyId, setProductFamilyId] = useState(0);

  const {
    data: orgData,
    isLoading,
    isError,
  } = useGetOrganizationQuery(
    {
      orgId: ticketOrgId ?? "",
    },
    { skip: !ticketOrgId },
  );

  useEffect(() => {
    if (productFamilyList.length > 0 && familyNameCode) {
      const id = productFamilyList.find(
        (productFamily) => productFamily.code === familyNameCode,
      )?.id;
      setProductFamilyId(id ?? 0);
    }
  }, [ticketProductFamily, productFamilyList, familyNameCode]);

  if (isLoading || isError) {
    return null;
  }

  if (productFamilyId !== 0 && orgData && orgData.data.length > 0) {
    return (
      <Flex direction="row" gap="size-100" marginX="size-100" alignItems="end">
        <ProjectSelector
          orgId={orgData.data[0].id}
          productFamilyId={productFamilyId}
        />
        <EnvironmentSelector
          orgId={orgData.data[0].id}
          productFamilyId={productFamilyId}
        />
      </Flex>
    );
  }

  return null;
};

export default InstanceSelectors;
