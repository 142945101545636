/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Flex, View, Text } from "@adobe/react-spectrum";
import OrgNameOrgInfoBannerElement from "./OrgNameOrgInfoBannerElement";
import { TicketInfoBannerElement } from "../augmentedAnalysis/TicketInfoBannerElement";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const AccountInfoBanner: React.FC = () => {
  const org = useSelector((state: RootState) => state.org);

  const orgInfo = [
    {
      value: (
        <OrgNameOrgInfoBannerElement
          orgId={org.id}
          orgName={org.name || "Loading..."}
        />
      ),
      size: "1 1 35%",
    },
    {
      title: "Org Id",
      value: (
        <Flex direction="column">
          <Text>{org.code || "Loading..."}</Text>
        </Flex>
      ),
      size: "1 1 65%",
    },
  ];

  return (
    <View
      UNSAFE_className="ticket-info"
      backgroundColor="gray-50"
      borderRadius="regular"
      paddingX="size-100"
      paddingY="size-150"
    >
      {orgInfo.map((info) => (
        <TicketInfoBannerElement
          key={info.title}
          title={info?.title || ""}
          value={info.value}
        />
      ))}
    </View>
  );
};

export default AccountInfoBanner;
