/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import { fetchAIResponseStream } from "../services/requestServices";
import {
  transitionState,
  setAiArticle,
} from "../store/knowledgeCenter/knowledgeCenterSlice";

export function useAiArticle() {
  const dispatch = useDispatch();
  const casePayload = useSelector((state: RootState) => state.case.casePayload);
  const caseId = casePayload?.caseId || "";
  const aiArticle = useSelector(
    (state: RootState) => state.knowledgeCenter.aiArticle,
  );
  const aiArticleStorage =
    localStorage.getItem("knowledgeArticle" + caseId) || "";
  const aiArticleText = aiArticle?.text || aiArticleStorage;
  const aiArticleIsLoading = aiArticle?.isLoading || false;

  const token = useSelector((state: RootState) => state.auth.token);

  const reload = () => {
    localStorage.removeItem("knowledgeArticle" + caseId);
    getAiArticle();
  };

  const getAiArticle = () => {
    const requestBody = {
      caseId,
      promptData: { "oasis-findings": [] },
    };
    const url = `${window.env.REACT_APP_API_BASE}dynamics/${caseId}/aiArticle`;
    dispatch(transitionState({ targetState: "loading" }));
    fetchAIResponseStream(url, token, requestBody, (response) => {
      // todo try to move setAiArticle to knowledgeCenterSlice
      dispatch(setAiArticle(response));
    }).then((obj) => {
      if (obj?.error) {
        dispatch(
          setAiArticle({
            text: "Sorry, but AI suggestions are not available at the moment.",
            isLoading: false,
          }),
        );
      } else {
        dispatch(transitionState({ targetState: "view" }));
      }
    });
  };

  return [aiArticleText, aiArticleIsLoading, getAiArticle, reload] as const;
}
