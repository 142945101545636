/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect } from "react";
import {
  Button,
  Flex,
  Heading,
  ProgressCircle,
  View,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import "./AiKnowledgeCenter.css";
import "@mdxeditor/editor/style.css";
import { useAiArticle } from "../../../hooks/useAiArticle";
import TableDataView from "../AiKnowledgeCenter/TableDataView";
import { useKbArticles } from "../../../hooks/useKbArticles";
import KbArticleView from "./KbArticleView";
import { KbArticleEdit } from "./KbArticleEdit";
import { useAiArticleDuplicateSearch } from "../../../hooks/useAiArticleDuplicateSearch";
import DuplicatedArticles from "./DuplicatedArticles";

const AiKnowledgeCenter: React.FC = () => {
  const [, , , reload] = useAiArticle();
  const casePayload = useSelector((state: RootState) => state.case.casePayload);
  const caseId = casePayload?.caseId || "";
  const [, getKbArticles] = useKbArticles(caseId);
  const [getAiArticleDuplicates] = useAiArticleDuplicateSearch(caseId);
  const knowledgeCenterState = useSelector(
    (state: RootState) => state.knowledgeCenter,
  );
  const kbArticlesFetched = knowledgeCenterState.kbArticlesFetched;
  const kbArticles = knowledgeCenterState.kbArticles;
  useEffect(() => {
    if (!kbArticlesFetched) {
      getKbArticles();
    }
  }, [kbArticlesFetched]);

  if (knowledgeCenterState.flags.isReadyToGenerateArticle) {
    return (
      <View>
        <Flex direction="column">
          <Flex direction="row" justifyContent="center">
            <View paddingY="size-1200" UNSAFE_style={{ textAlign: "center" }}>
              <Heading level={2}>
                Let&rsquo;s get started generating Knowledge Article!
              </Heading>
              <Button
                variant="cta"
                onPress={(e) => {
                  getAiArticleDuplicates();
                }}
              >
                Generate a New Article from this Case Details
              </Button>
            </View>
          </Flex>
        </Flex>
      </View>
    );
  }

  if (knowledgeCenterState.flags.isShowDuplicatedArticlesList) {
    return <DuplicatedArticles />;
  }

  if (knowledgeCenterState.flags.isShowArticlesList) {
    let kbArticlesCollection = [];
    let contentMap: { [key: string]: string } = {};
    for (let i = 0; i < kbArticles.length; i++) {
      kbArticlesCollection.push({
        "JIRA ID": kbArticles[i].jira_id,
        DESCRIPTION: kbArticles[i].summary,
        "ARTICLE USE FOR": kbArticles[i].is_public ? "Public" : "Internal",
        STATUS: kbArticles[i].status,
        "REVIEWED BY": kbArticles[i].reviewed_by,
      });
      contentMap[kbArticles[i].jira_id] = kbArticles[i].content;
    }
    return TableDataView(kbArticlesCollection);
  }

  if (knowledgeCenterState.flags.isEditing) {
    return <KbArticleEdit />;
  }

  if (knowledgeCenterState.flags.isView) {
    return <KbArticleView />;
  }

  return (
    <View>
      <ProgressCircle aria-label="Loading…" isIndeterminate />
    </View>
  );
};
export default AiKnowledgeCenter;
