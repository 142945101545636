/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Cell,
  Column,
  Flex,
  Link,
  ProgressCircle,
  Row,
  TableBody,
  TableHeader,
  TableView,
  Text,
  View,
} from "@adobe/react-spectrum";
import { ToastQueue } from "@react-spectrum/toast";
import { findIndex, set } from "lodash";
import {
  COLUMNS_SEARCH_TABLE,
  SEARCH_PER_PAGE,
  SearchRequest,
  SearchResult,
  SearchResultTableProps,
  defaultSearchResultValue,
} from "../../constants/search";
import { useGetTicketListMutation } from "../../services/supportInsights";
import { setCaseList } from "../../store/case/caseSlice";
import { RootState } from "../../store/store";
import DropDownFilter, {
  ORDER_ACTION,
  SortBy,
  handleSortIcon,
} from "../common/DropDownFilter";
import Pagination from "../common/Pagination";
import getApiRoute from "../../utils/getApiRoute";
import MessageComponent from "../common/MessageComponent";

const filterURL = getApiRoute(`/api/v1/dynamics/search/filter`);

const SearchResultTable: React.FC<SearchResultTableProps> = ({
  searchText,
}) => {
  document.title = "Search";
  defaultSearchResultValue.searchValue = "";
  const [caseDetails, setCaseDetails] = React.useState<SearchResult>(
    defaultSearchResultValue,
  );
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [sortOrder, setSortOrder] = useState<SortBy>({
    updated_at: "descending",
  });
  const [filterCase, setFilterCase] = useState({});

  const caseItems = useSelector((state: RootState) => state.case.caseList);
  const items =
    Array.isArray(caseItems?.data) && caseItems?.data !== undefined
      ? caseItems?.data
      : [];
  const error = caseItems?.meta?.errors?.publicMessage ?? "";
  const ticketsCount = caseItems?.meta?.tickets_count ?? 0;
  const search = searchText ?? "";
  const [caseList, { isLoading: caseIsLoading }] = useGetTicketListMutation();

  const dispatch = useDispatch();
  const requestPayload = { query: search, filter: filterCase };

  const handleSortOrder = (columnName: string, orderBy: ORDER_ACTION) => {
    let sortOrderResult = {};
    const searchColumnIndex = findIndex(COLUMNS_SEARCH_TABLE, [
      "uid",
      columnName,
    ]);
    set(sortOrderResult, columnName, orderBy);
    set(requestPayload, "page", 1);
    set(requestPayload, "sortOrder", sortOrderResult);
    setCurrentPage(1);
    setSortOrder(sortOrderResult);
    callSearchCase(requestPayload);
    handleSortIcon(searchColumnIndex);
  };

  const handleFilter = (columnName: string, filterList: string[]) => {
    let filterColumns = {}; //filterCase;
    set(requestPayload, "page", 1);
    setCurrentPage(1);
    if (sortOrder !== null) {
      set(requestPayload, "sortOrder", sortOrder);
    }
    localStorage.setItem("filterColumnName", columnName);
    set(filterColumns, columnName, filterList);
    setFilterCase(filterColumns);
    set(requestPayload, "filter", filterColumns);
    callSearchCase(requestPayload);
  };

  const handlePaging = (page: number) => {
    if (page > 0 && page !== currentPage) {
      set(requestPayload, "page", page);
      if (sortOrder !== null) {
        set(requestPayload, "sortOrder", sortOrder);
      }
      if (filterCase !== null) {
        set(requestPayload, "filter", filterCase);
      }
      setCurrentPage(page);
      callSearchCase(requestPayload);
    }
  };

  const callSearchCase = (requestPayload: SearchRequest) => {
    if (requestPayload.query !== "") {
      caseList(requestPayload)
        .unwrap()
        .then((response) => {
          if (response?.meta.errors !== undefined) {
            dispatch(setCaseList({ data: [], meta: response?.meta }));
          } else {
            dispatch(setCaseList({ ...response }));
          }
        })
        .catch((error: any) => {
          // Data is still loading. Please be patient, we will retrieve the data shortly.
          ToastQueue.negative(
            "Sorry for the inconvenience. We are facing Data Retrieval error Please visit after sometime.",
            { timeout: 5000 },
          );
        });
    }
  };

  useEffect(() => {
    if (!caseIsLoading) {
      setCaseDetails((prevState) => ({
        ...prevState,
        caseItems: [...items],
        errorMessage: error,
        searchValue: searchText ?? "",
        totalItem: ticketsCount,
      }));
      if (error) {
        ToastQueue.negative(error, { timeout: 5000 });
      }
    }
  }, [searchText, caseItems, items]);
  if (searchText === "") {
    return (
      <MessageComponent title={"Search Results is Loading"} isLoading={true}>
        We are fetching your Search result.
      </MessageComponent>
    );
  }

  return (
    <div className="data-container-wrapper">
      <View
        borderRadius="regular"
        backgroundColor="gray-50"
        UNSAFE_style={{ minHeight: "calc(100vh - 210px)" }}
      >
        <Flex direction="column" gap="size-50">
          <View padding="size-250" UNSAFE_className="search-table-header">
            <Flex
              gap="size-150"
              direction="row"
              alignContent="center"
              justifyContent="space-between"
            >
              <p className="aug-analysis-header">
                {`Search Results for "${searchText}"`}
              </p>
            </Flex>
          </View>
          <View paddingX="size-1000">
            <Flex
              direction="column"
              justifyContent="center"
              alignItems="center"
            >
              <Flex
                direction="column"
                justifyContent="start"
                alignItems="start"
                width="100%"
              >
                <Text UNSAFE_className="search-table-heading">
                  {caseDetails.totalItem} search results found
                </Text>

                <View width="100%" UNSAFE_className="search-table-wrapper">
                  {caseIsLoading && (
                    <View
                      backgroundColor="gray-400"
                      padding="size-300"
                      UNSAFE_className="search-table-loading-wrapper"
                    >
                      <ProgressCircle
                        aria-label="Loading…"
                        staticColor="white"
                        isIndeterminate
                      />
                    </View>
                  )}
                  <TableView UNSAFE_className="table-container">
                    <TableHeader columns={COLUMNS_SEARCH_TABLE}>
                      {(column) => (
                        //   @ts-ignore
                        <Column key={column.uid} width={column.width}>
                          {column.filter || column.sortBy ? (
                            <DropDownFilter
                              name={column.name}
                              columnName={column.uid}
                              filterOption={column.filter}
                              endpointFilter={filterURL.href}
                              filterQuery={{
                                query: searchText,
                                page: 1,
                                filterName: column.uid,
                              }}
                              hasColumnSortBy={
                                column.sortBy &&
                                Object.keys(sortOrder)[0] === column.uid
                              }
                              options={[]}
                              onSortOrder={handleSortOrder}
                              onApplyFilter={(items: string[]) =>
                                handleFilter(column.uid, items)
                              }
                            />
                          ) : (
                            column.name
                          )}
                        </Column>
                      )}
                    </TableHeader>
                    <TableBody>
                      {caseDetails.caseItems.map((caseItem, caseIndex) => (
                        <Row key={caseIndex}>
                          <Cell>
                            <Link
                              href={`/review/case/${caseItem.dynamics_id}/org_id/${caseItem.case_org_id}/renga_id/${caseItem?.case_renga_id}`}
                              target={"_blank"}
                              UNSAFE_className="analytics-track-me"
                              data-analytics-link-name={caseItem.caseId}
                              data-analytics-view-name="Cases list"
                            >
                              {caseItem.caseId}
                            </Link>
                          </Cell>
                          <Cell>{caseItem.case_product_name ?? "Unknown"}</Cell>
                          <Cell>
                            {
                              <Link
                                href={"/accounts/" + caseItem.case_org_id}
                                UNSAFE_className="analytics-track-me"
                                data-analytics-link-name={caseItem.case_org_id}
                                data-analytics-view-name="Cases list"
                                target={"_blank"}
                              >
                                {caseItem.case_org_id ?? "Unknown"}
                              </Link>
                            }
                          </Cell>
                          <Cell>{caseItem.org_name ?? "Unknown"}</Cell>
                          <Cell>{caseItem.case_status ?? "Unknown"}</Cell>
                          <Cell>{caseItem.case_title ?? "Unknown"}</Cell>
                          <Cell>{caseItem.updated_at ?? "Unknown"}</Cell>
                        </Row>
                      ))}
                    </TableBody>
                  </TableView>
                </View>
              </Flex>
            </Flex>
          </View>
          <View paddingX="size-450" paddingY="size-250">
            <Pagination
              className="pagination-bar"
              currentPage={currentPage}
              totalCount={caseDetails.totalItem}
              pageSize={SEARCH_PER_PAGE}
              onPageChange={handlePaging}
              siblingCount={3}
            />
          </View>
        </Flex>
      </View>
    </div>
  );
};

export default SearchResultTable;
