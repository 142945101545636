/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { useGetUserWatchlistQuery } from "../../services/supportInsightsUserPersona";
import HomePageCardWrapper from "./HomePageCardWrapper";
import UserOrgWatchlistTable from "./UserOrgWatchlistTable";
import { isWatchlistOrgResponse } from "../../types/watchlist";

const UserOrgWatchlistCard: React.FC = () => {
  const { data, isLoading, error } = useGetUserWatchlistQuery({
    itemType: "org",
  });

  const isValid = data && isWatchlistOrgResponse(data);

  // Filter out null values from the data array if valid
  const filteredResponse = isValid
    ? { ...data, data: data.data.filter((org) => org !== null) }
    : undefined;

  const itemCount = data?.meta?.item_count;

  return (
    <HomePageCardWrapper
      isLoading={isLoading}
      error={error}
      data={filteredResponse ? filteredResponse.data : []}
      title="Account Watchlist"
      itemCount={itemCount}
      noDataTitle="Empty Account Watchlist"
      noDataDescription="Add accounts to your watchlist to view them here."
      errorMessage="There was an error fetching your account watchlist. Please try again later."
    >
      {filteredResponse && <UserOrgWatchlistTable data={filteredResponse} />}
    </HomePageCardWrapper>
  );
};

export default UserOrgWatchlistCard;
