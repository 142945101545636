/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { RootState } from "../store/store";
import { setIsDataCollecting } from "../store/case/caseSlice";
import {
  DataMetaResponse,
  ProductStatusResponse,
  GetDatapointsResponse,
  DataPoint,
} from "../types/datapoints";
import { DynamicsCollectMutationArgument } from "../types/dynamicsCollect";
import { IsProductFamilySupported } from "../types/isProductFamilySupported";
import {
  CaseCoulumnFilterRequest,
  CaseCoulumnFilterResponse,
  CaseResponse,
  GetTicketResponse,
  DataObjectWithToken,
  UserRoleResponse,
  BannerResponse,
  ProductFamilyResponse,
  ProjectResponse,
  EnvironmentResponse,
} from "../types/case";
import { GetOrganizationResponse } from "../types/org";
import { SearchRequest } from "../constants/search";
import { GetProductFamiliesResponse } from "../types/ProductFamily";
import { GetJobRolesResponse } from "../types/JobRole";
import * as Watchlist from "../types/watchlist";
import { GetFindingsResponse } from "../types/finding";

const waitForToken = async (getState: () => RootState): Promise<string> => {
  let token = (getState() as RootState).auth.token;
  while (!token) {
    await new Promise((resolve) => setTimeout(resolve, 100)); // Poll every 100ms
    token = (getState() as RootState).auth.token;
  }
  return token;
};

export const supportInsightsApi = createApi({
  reducerPath: "supportInsightsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: window.env.REACT_APP_API_BASE,
    timeout: 600000,
    prepareHeaders: async (headers, { getState }) => {
      const token = await waitForToken(getState as () => RootState);
      if (token) {
        headers.set("X-OKTA-Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ["Datapoints", "Findings", "Watchlists"],
  endpoints: (builder) => ({
    getTicketList: builder.mutation<CaseResponse, SearchRequest>({
      query: (params: SearchRequest) => {
        return {
          url: `dynamics/search`,
          method: "post",
          body: params,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    postBannerData: builder.mutation<void, DataObjectWithToken>({
      query: ({ dataObject, token }) => ({
        url: "/application/settings",
        method: "POST",
        body: dataObject,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    updateBannerData: builder.mutation({
      query: ({ id, dataObject, token }) => ({
        url: `/application/settings/${id}`,
        method: "PUT",
        body: dataObject,
      }),
    }),
    getOrganization: builder.query<GetOrganizationResponse, { orgId: string }>({
      query: ({ orgId }) => ({
        url: `orgs?code=${orgId}`,
      }),
    }),
    getProductFamily: builder.query<ProductFamilyResponse, { orgId: number }>({
      query: ({ orgId }) => ({
        url: `orgs/${orgId}/product-families`,
      }),
    }),
    getProject: builder.query<
      ProjectResponse,
      { orgId: number; productFamilyId: number }
    >({
      query: ({ orgId, productFamilyId }) => ({
        url: `/orgs/${orgId}/product-families/${productFamilyId}/projects`,
      }),
    }),
    getEnvironment: builder.query<
      EnvironmentResponse,
      { orgId: number; productFamilyId: number; projectId: number | string }
    >({
      query: ({ orgId, productFamilyId, projectId }) => ({
        url: `orgs/${orgId}/product-families/${productFamilyId}/projects/${projectId}/environments`,
      }),
    }),
    getUserBannerData: builder.query<
      BannerResponse[],
      { CODE: string; token: string }
    >({
      query: ({ CODE, token }) => ({
        url: `/application/settings?code=${CODE}`,
      }),
    }),
    deleteBanner: builder.mutation<void, { id: number; token: string }>({
      query: ({ id, token }) => ({
        url: `/application/settings/${id}`,
        method: "DELETE",
      }),
    }),
    getTicketFilterColumn: builder.mutation<
      CaseCoulumnFilterResponse,
      CaseCoulumnFilterRequest
    >({
      query: (params) => {
        return {
          url: `dynamics/search/filter`,
          method: "post",
          body: params,
          headers: {
            "Content-type": "application/json",
          },
        };
      },
    }),
    getUserRole: builder.query<UserRoleResponse, string>({
      query: (token) => ({
        url: "/user/role",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
    getTicketById: builder.query<GetTicketResponse, { ticketId: string }>({
      query: ({ ticketId }) => `dynamics/tickets/${ticketId}`,
    }),
    getTicketByFullInfo: builder.query<
      GetTicketResponse,
      { ticketId: string; orgId: string; rengaId: string }
    >({
      query: ({ ticketId, orgId, rengaId }) =>
        `dynamics/${ticketId}/org/${orgId}/rengaId/${rengaId}`,
    }),
    getDataPoints: builder.query<
      GetDatapointsResponse,
      { caseId: string; productFamily: string; environmentId?: string | null }
    >({
      query: ({ caseId, productFamily, environmentId = null }) =>
        `dynamics/product-families/${productFamily}/tickets/${caseId}/${environmentId ? `environments/${environmentId}/` : ""}datapoints`,
      providesTags: ["Datapoints"],
    }),
    getFindings: builder.query<
      GetFindingsResponse,
      { caseId: string; productFamily: string; environmentId?: string | null }
    >({
      query: ({ caseId, productFamily, environmentId = null }) =>
        `dynamics/product-families/${productFamily}/tickets/${caseId}/${environmentId ? `environments/${environmentId}/` : ""}findings`,
      providesTags: ["Findings"],
    }),
    getFindingFilter: builder.query({
      query: ({
        caseId,
        productFamily,
        filterName,
      }: {
        caseId: string;
        productFamily: string;
        filterName: string;
      }) =>
        `products/${productFamily}/dynamics/${caseId}/findings/filter?filterName=${filterName}&page=1`,
    }),
    getIsProductFamilySupported: builder.query<
      IsProductFamilySupported,
      string
    >({
      query: (productFamilyCode: string) => `products/${productFamilyCode}`,
    }),
    getDataPointById: builder.query<
      GetDatapointsResponse,
      { datapointId: number }
    >({
      query: ({ datapointId }) => `/datapoint/${datapointId}`,
    }),
    dynamicsCollectData: builder.mutation<
      DataMetaResponse<{ data_points: unknown[]; findings: unknown[] }>,
      DynamicsCollectMutationArgument
    >({
      query: ({
        payload,
        productFamilyCode,
        ticketId,
        requestType = "POST",
      }) => ({
        url: `dynamics/product-families/${productFamilyCode}/tickets/${ticketId}/datapoints/collect`,
        method: requestType,
        body: payload,
      }),
      invalidatesTags: ["Datapoints", "Findings"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(setIsDataCollecting(true));
        try {
          await queryFulfilled;
        } catch (error) {
          console.error(error);
          dispatch(setIsDataCollecting(false));
        } finally {
          dispatch(setIsDataCollecting(false));
        }
      },
    }),
    getDataPointByCode: builder.query<
      GetDatapointsResponse,
      { familyCode: string; ticketId: string; code: string }
    >({
      query: ({ familyCode, ticketId, code }) =>
        `dynamics/product-families/${familyCode}/tickets/${ticketId}/datapoints?code=${code}`,
    }),
    refreshTicket: builder.mutation<
      GetTicketResponse,
      { caseId: string; orgId: string; rengaId: string }
    >({
      query: ({ caseId, orgId, rengaId }) => ({
        url: `dynamics/${caseId}/org/${orgId}/rengaId/${rengaId}/refresh/`,
        method: "PUT",
      }),
    }),
    productStatus: builder.query<
      ProductStatusResponse[],
      { productFamily: string }
    >({
      query: ({ productFamily }) => ({
        url: `products/${productFamily}/adobeStatus`,
        method: "GET",
      }),
    }),
    getProductFamilies: builder.query<GetProductFamiliesResponse, void>({
      query: () => ({
        url: "product-families",
        method: "GET",
      }),
    }),
    getJobRoles: builder.query<GetJobRolesResponse, void>({
      query: () => ({
        url: "job-roles",
        method: "GET",
      }),
    }),
    collectDatapointsByEnvironmentId: builder.mutation<
      DataMetaResponse<{ data_points: DataPoint[] }>,
      {
        environmentId: number;
        productFamilyCode: string;
        ticketId: string;
      }
    >({
      query: ({ environmentId, productFamilyCode, ticketId }) => ({
        url: `dynamics/product-families/${productFamilyCode}/tickets/${ticketId}/environments/${environmentId}/datapoints/collect`,
        method: "PUT",
      }),
      invalidatesTags: ["Datapoints", "Findings"],
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        dispatch(setIsDataCollecting(true));
        try {
          await queryFulfilled;
        } catch (error) {
          console.error(error);
          dispatch(setIsDataCollecting(false));
        } finally {
          dispatch(setIsDataCollecting(false));
        }
      },
    }),
  }),
});

export const {
  useGetDataPointByIdQuery,
  useGetDataPointsQuery,
  useGetFindingsQuery,
  useGetFindingFilterQuery,
  useGetIsProductFamilySupportedQuery,
  useGetTicketByIdQuery,
  useDynamicsCollectDataMutation,
  useGetDataPointByCodeQuery,
  useGetTicketByFullInfoQuery,
  useRefreshTicketMutation,
  useProductStatusQuery,
  useGetTicketListMutation,
  useGetTicketFilterColumnMutation,
  usePostBannerDataMutation,
  useGetUserRoleQuery,
  useGetUserBannerDataQuery,
  useDeleteBannerMutation,
  useUpdateBannerDataMutation,
  useGetOrganizationQuery,
  useGetProductFamilyQuery,
  useGetProjectQuery,
  useLazyGetProjectQuery,
  useGetEnvironmentQuery,
  useLazyGetEnvironmentQuery,
  useGetProductFamiliesQuery,
  useGetJobRolesQuery,
  useCollectDatapointsByEnvironmentIdMutation,
} = supportInsightsApi;
