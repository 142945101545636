/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { ActionButton, Flex, View, Text } from "@adobe/react-spectrum";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import Copy from "@spectrum-icons/workflow/Copy";
import Refresh from "@spectrum-icons/workflow/Refresh";
import Preview from "@spectrum-icons/workflow/Preview";
import React from "react";
import { ToastQueue } from "@react-spectrum/toast";
import { useAiArticle } from "../../../hooks/useAiArticle";
import { transitionState } from "../../../store/knowledgeCenter/knowledgeCenterSlice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";

function KbArticleView() {
  const [aiArticleText, aiArticleIsLoading, getAiArticle, reload] =
    useAiArticle();
  const casePayload = useSelector((state: RootState) => state.case.casePayload);
  const caseId = casePayload?.caseId || "";
  const dispatch = useDispatch();

  return (
    <View>
      <Flex direction="column">
        <View UNSAFE_className="response">
          <ReactMarkdown remarkPlugins={[remarkGfm]}>
            {aiArticleText}
          </ReactMarkdown>
          <Flex
            direction="row"
            gap="size-200"
            wrap="wrap"
            marginTop={"size-500"}
          >
            <Flex>
              <ActionButton
                onPress={() => {
                  navigator.clipboard
                    .writeText(aiArticleText || "")
                    .then(() =>
                      ToastQueue.positive("Copied to clipboard", {
                        timeout: 3000,
                      }),
                    )
                    .catch(() =>
                      ToastQueue.negative("Failed to copy to clipboard", {
                        timeout: 5000,
                      }),
                    );
                }}
                UNSAFE_style={{ paddingRight: "10px" }}
              >
                <Copy /> <Text>Copy</Text>
              </ActionButton>
            </Flex>
            <Flex>
              <ActionButton
                isDisabled={aiArticleIsLoading}
                onPress={() => reload()}
                UNSAFE_style={{ paddingRight: "10px" }}
              >
                <Refresh /> <Text>Refresh</Text>
              </ActionButton>
            </Flex>
            <Flex>
              <ActionButton
                isDisabled={aiArticleIsLoading}
                onPress={() => {
                  localStorage.setItem(
                    "knowledgeArticle" + caseId,
                    aiArticleText || "",
                  );
                  dispatch(transitionState({ targetState: "editing" }));
                }}
                UNSAFE_style={{ paddingRight: "10px" }}
              >
                <Preview /> <Text>Edit & Submit</Text>
              </ActionButton>
            </Flex>
          </Flex>
        </View>
      </Flex>
    </View>
  );
}

export default KbArticleView;
