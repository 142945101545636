/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { Dispatch } from "redux";
import { setCasePayload, setIsTicketRefreshing } from "../store/case/caseSlice";
import * as analytics from "../utils/analytics";

interface HandleRefreshParams {
  caseId: string;
  orgId: string;
  rengaId: string;
  productFamily: string;
  refreshTicketData: any;
  setIsDisabled: (disabled: boolean) => void;
  dispatch: Dispatch;
}

export const handleRefresh = async ({
  caseId,
  orgId,
  rengaId,
  productFamily,
  refreshTicketData,
  setIsDisabled,
  dispatch,
}: HandleRefreshParams) => {
  setIsDisabled(true);
  dispatch(setIsTicketRefreshing(true));
  analytics.track("refreshButtonClicked", {
    caseId: caseId,
    solution: productFamily,
    orgId: orgId,
  });
  try {
    const refreshResponse = await refreshTicketData({
      caseId: caseId,
      orgId: orgId,
      rengaId: rengaId,
    }).unwrap();
    dispatch(setCasePayload(refreshResponse.data.payload));
    dispatch(setIsTicketRefreshing(false));
    return refreshResponse.data;
  } catch (error) {
    setIsDisabled(false);
    dispatch(setIsTicketRefreshing(false));
  }
};
