/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import {
  View,
  TabList,
  Item,
  TabPanels,
  Tabs,
  Flex,
  ActionGroup,
  Divider,
} from "@adobe/react-spectrum";
import DefectsTable from "./DefectsTable";
import { useNavigate } from "react-router-dom";

const DefectDashboard: React.FC = () => {
  const navigate = useNavigate();
  const handleCreateDefect = () => {
    navigate("/workfront/create-defect");
  };

  return (
    <Tabs aria-label="Augmented Analysis Tabs">
      <Flex direction={"row"}>
        <TabList flex="1 1 auto" minWidth="0px">
          <Item key="my-defects">My Defect Overview</Item>
          <Item key="all-defects">All Defects</Item>
        </TabList>
        <Flex gap={"size-200"} direction={"column"}>
          <ActionGroup onAction={handleCreateDefect}>
            <Item>Create Defect</Item>
          </ActionGroup>
          <Divider size="M" />
        </Flex>
      </Flex>
      <TabPanels marginTop="size-200">
        <Item key="my-defects">
          <View padding={"size-300"}></View>
          <DefectsTable defectType="getMyDefects" />
        </Item>
        <Item key="all-defects">
          <View padding={"size-300"}></View>
          <DefectsTable defectType="getAllDefects" />
        </Item>
      </TabPanels>
    </Tabs>
  );
};

export default DefectDashboard;
