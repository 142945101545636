/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { useGetUserWatchlistQuery } from "../../services/supportInsightsUserPersona";
import HomePageCardWrapper from "./HomePageCardWrapper";
import { isWatchlistTicketResponse } from "../../types/watchlist";
import TicketTable from "./TicketTable";

const UserTicketWatchlistCard: React.FC = () => {
  const { data, isLoading, error } = useGetUserWatchlistQuery({
    itemType: "ticket",
  });

  const isValid = data && isWatchlistTicketResponse(data);

  const tickets = isValid
    ? data.data.filter((ticket) => ticket !== null).map((ticket) => ticket.item)
    : [];
  const itemCount = data?.meta?.item_count;

  return (
    <HomePageCardWrapper
      isLoading={isLoading}
      error={error}
      data={tickets}
      title="Case Watchlist"
      itemCount={itemCount}
      noDataTitle="Empty Case Watchlist"
      noDataDescription="Add cases to your watchlist to view them here"
      errorMessage="There was an error fetching your case watchlist. Please try again later."
    >
      <TicketTable data={tickets} />
    </HomePageCardWrapper>
  );
};

export default UserTicketWatchlistCard;
