/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import {
  View,
  Flex,
  ContextualHelp,
  Content,
  Heading,
} from "@adobe/react-spectrum";
import { ReactNode } from "react";

interface BannerElementProps {
  title: string;
  value: ReactNode;
  help?: JSX.Element;
  size?: string;
  wideElementClass?: string;
  analyticCaseFieldClass?: string;
}

export const TicketInfoBannerElement: React.FC<BannerElementProps> = ({
  title,
  value,
  help,
  size,
  // FIXME: Combine these two into a single className prop
  wideElementClass = "",
  analyticCaseFieldClass = "",
}) => {
  return (
    <View paddingX="size-200" UNSAFE_style={{ flex: size }}>
      <Flex
        direction="column"
        minWidth="size-1500"
        height="max-content"
        gap="size-50"
        flex="1"
      >
        {title && (
          <div className="ticket-info__header">
            <span className="ticket-info__section-title">{title}</span>
            {help && (
              <ContextualHelp
                variant="help"
                alignSelf={"flex-end"}
                placement="right top"
                UNSAFE_className="ticket-info__ContextualHelp"
              >
                <Heading>{title}</Heading>
                <Content>{help}</Content>
              </ContextualHelp>
            )}
          </div>
        )}
        <Flex
          UNSAFE_className={`ticket-info__data ${wideElementClass} ${analyticCaseFieldClass}`}
          alignItems={"center"}
          justifyContent={"start"}
          alignContent={"start"}
        >
          {value}
        </Flex>
      </Flex>
    </View>
  );
};
