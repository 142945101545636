/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { InternalNote } from "../../types/case";
import { Flex, Text } from "@adobe/react-spectrum";
import WatchlistSwitch from "./WatchlistSwitch";
import { Link } from "react-router-dom";

interface OrgInfoProps {
  orgId: number;
  orgCode: string | undefined;
  orgName: string | undefined;
  orgInternalNotes: InternalNote[] | undefined;
}

const OrgInfo: React.FC<OrgInfoProps> = ({ orgId, orgCode }) => {
  return (
    <Flex direction="column" gap={5}>
      <Text>{orgCode ? orgCode : "Org Id Unavailable"}</Text>
      <Link to={"/accounts/" + orgCode} className="user-page-table-row-link">
        View Account Details
      </Link>
      <WatchlistSwitch itemId={orgId} itemType="org" />
    </Flex>
  );
};

export default OrgInfo;
