/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { FocusEvent, useState } from "react";
import {
  View,
  Button,
  Flex,
  Dialog,
  Heading,
  Content,
  TextArea,
  ButtonGroup,
  DialogTrigger,
  Checkbox,
  TextField,
  ContextualHelp,
  InlineAlert,
} from "@adobe/react-spectrum";
import ThumbUp from "@spectrum-icons/workflow/ThumbUp";
import ThumbDown from "@spectrum-icons/workflow/ThumbDown";
import { Form } from "@adobe/react-spectrum";
import getApiRoute from "../../utils/getApiRoute";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { ValidationErrors } from "@react-types/shared";
import { ToastQueue } from "@react-spectrum/toast";

function FeedbackForm() {
  const [feedbackType, setFeedbackType] = useState<"up" | "down" | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [minutes, setMinutes] = useState<string>("10");
  const [comment, setComment] = useState("");
  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>();
  const caseID = useSelector(
    (state: RootState) => state.case?.casePayload?.caseId,
  );
  const userObject = useSelector((state: RootState) => state.user);

  const pageHeader = document.getElementsByClassName("aug-analysis-header")[0];
  const pageTitle = pageHeader?.textContent
    ? pageHeader?.textContent.trim()
    : "";

  const token = useSelector((state: RootState) => state.auth.token);

  const handleCheckboxChange = (e: FocusEvent<Element>) => {
    const element = e.target;
    let isChecked = false;
    let text = "";
    if (!(element instanceof HTMLInputElement)) {
      return;
    }
    if (element.type === "checkbox") {
      isChecked = element.checked;
      text = element.parentElement?.textContent ?? "";
    } else {
      let parent = element.parentElement?.parentElement?.parentElement;
      let checkbox = parent?.querySelector('input[type="checkbox"]');
      if (checkbox instanceof HTMLInputElement) {
        isChecked = checkbox.checked;
        text = checkbox.parentElement?.textContent ?? "";
      }
    }
    if (
      feedbackType === "up" &&
      text.startsWith("Like this feature, it has saved")
    ) {
      if (minutes === "") {
        setValidationErrors({
          likeThisFeatureCheckbox: "Please enter a number of minutes.",
        });
      } else {
        setValidationErrors({});
      }
      text = text + minutes + " Min.";
    }

    let selectedOptionsFiltered = selectedOptions.filter(
      (option) => !option.startsWith(text.substring(0, 20)),
    );
    if (text && isChecked) {
      setSelectedOptions([...selectedOptionsFiltered, text]);
    } else {
      setSelectedOptions(selectedOptionsFiltered);
    }
  };

  function handleLikeFeatureChange(isSelected: boolean) {
    const savedMinutes = document.querySelectorAll(
      'input[type="text"][aria-label="minutes"]',
    )[0];
    if (savedMinutes instanceof HTMLInputElement) {
      if (isSelected) {
        savedMinutes.focus();
        setMinutes("");
      } else {
        if (minutes === "") {
          setMinutes("10");
        }
      }
    }
  }

  const handleFeedback = (type: "up" | "down") => {
    setFeedbackType(type);
  };

  const handleDismiss = () => {
    setFeedbackType(null);
    setSelectedOptions([]);
    setComment("");
    setMinutes("10");
    setValidationErrors({});
  };

  const handleSubmit = async () => {
    // Ensure form is valid before submitting
    if (
      (feedbackType == "up" &&
        selectedOptions.length === 0 &&
        comment.length === 0) ||
      (feedbackType == "down" && comment.length < 50)
    ) {
      if (feedbackType == "up") {
        setValidationErrors({
          form: "Please select at least one option or enter a comment.",
        });
      } else if (feedbackType == "down") {
        setValidationErrors({
          commentDown:
            "Please enter a comment explaining the reason for your vote. Please enter at least 50 characters.",
        });
      }
      return false;
    }
    if (
      validationErrors &&
      Object.getOwnPropertyNames(validationErrors).length > 0
    ) {
      setValidationErrors(validationErrors);
      return false;
    }

    const responseSummary =
      document.querySelectorAll('[id="ai-response"]')[0]?.textContent ?? "";

    let context = {
      "tab-content":
        pageTitle && responseSummary
          ? pageTitle + " - " + responseSummary
          : pageTitle ?? "",
    };

    let feedbackOptions = selectedOptions;
    if (feedbackOptions.length === 0) {
      feedbackOptions = ["No options selected"];
    }

    const data = {
      feedbackType: feedbackType,
      caseNumber: caseID ? caseID : "N/A",
      userId: userObject.userDetails?.email,
      feedbackDescription: comment,
      feedbackOptions: feedbackOptions,
      url: window.location.href,
      pageTitle: pageTitle ? pageTitle : "N/A",
      context: context,
    };

    const feedbackURL = getApiRoute(`/api/v1/user/feedback`);

    try {
      const response = await fetch(feedbackURL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "X-OKTA-Authorization": `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        ToastQueue.positive("Feedback submitted successfully!", {
          timeout: 5000,
        });
      } else {
        ToastQueue.negative(
          "An error occurred while submitting feedback. Please try again later.",
          {
            timeout: 5000,
          },
        );
        console.log("Failed to submit feedback.", response);
        return false;
      }
      setComment("");
      setMinutes("10");
      setSelectedOptions([]);
      return true;
    } catch (error) {
      ToastQueue.negative(
        "An error occurred while submitting feedback. Please try again later.",
        {
          timeout: 5000,
        },
      );
      console.log("An error occurred while submitting your feedback.", error);
    }
    return false;
  };

  return (
    <View
      position="fixed"
      right="size-200"
      height="100vh"
      UNSAFE_style={{
        display: "flex",
        alignItems: "center",
        color: "#505050",
        pointerEvents: "none",
      }}
    >
      <div className="feedback-form">
        <Flex
          direction="column"
          gap="size-100"
          UNSAFE_style={{ pointerEvents: "all" }}
        >
          <DialogTrigger
            type="popover"
            placement="left"
            offset={10}
            crossOffset={0}
            onOpenChange={(isOpen) => {
              !isOpen && handleDismiss();
            }}
          >
            <Button
              variant="cta"
              onPress={() => handleFeedback("up")}
              aria-label="Thumbs Up"
              aria-pressed={feedbackType === "up" ? "true" : "false"}
              UNSAFE_className="analytics-track-me"
              data-analytics-link-name="Thumbs Up"
              data-analytics-view-name="Feedback"
            >
              <ThumbUp color="positive" width="size-300" height="size-300" />
            </Button>
            {(closeUp) => (
              <Dialog onDismiss={handleDismiss} aria-label="feedback-dialog">
                <Heading>
                  <ThumbUp
                    aria-label="Thumbs Up"
                    color="positive"
                    width="size-300"
                    height="size-300"
                  />
                  <span>
                    We are glad. Can you tell us how{" "}
                    {pageTitle ? (
                      <>
                        <em>{pageTitle}</em>
                      </>
                    ) : (
                      "this"
                    )}{" "}
                    page helped you?
                  </span>
                </Heading>
                <Content>
                  {validationErrors?.form && (
                    <InlineAlert variant="negative" autoFocus>
                      <Heading>Unable to submit</Heading>
                      <Content>{validationErrors.form}</Content>
                    </InlineAlert>
                  )}
                  <Form
                    maxWidth="size-5000"
                    validationBehavior="native"
                    validationErrors={validationErrors}
                  >
                    <Flex direction="column" gap="size-200">
                      <Flex direction="column" gap="size-0">
                        <Checkbox onBlur={handleCheckboxChange}>
                          Found the answer to my question/problem
                        </Checkbox>
                        <div className={"checkbox-saved"}>
                          <Flex alignItems="center" direction={"row"}>
                            <Checkbox
                              onBlur={handleCheckboxChange}
                              onChange={handleLikeFeatureChange}
                              name={"likeThisFeatureCheckbox"}
                            >
                              Like this feature, it has saved{" "}
                            </Checkbox>
                            <TextField
                              name={"minutes"}
                              inputMode="numeric"
                              width="10px"
                              value={minutes}
                              aria-label={"minutes"}
                              onBlur={handleCheckboxChange}
                              onChange={(value) => {
                                setMinutes(value);
                              }}
                            />
                            <View marginStart="size-300">Min.</View>
                          </Flex>
                        </div>
                        {validationErrors?.likeThisFeatureCheckbox && (
                          <Content
                            UNSAFE_style={{
                              color: "#d31510",
                              fontSize: "12px",
                            }}
                          >
                            {validationErrors.likeThisFeatureCheckbox}
                          </Content>
                        )}
                      </Flex>
                      <TextArea
                        width={"100%"}
                        label={"Details / Other comments"}
                        placeholder="Type here (optional)"
                        value={comment}
                        name={"commentUp"}
                        onChange={(value) => {
                          setComment(value);
                        }}
                      />
                    </Flex>
                  </Form>
                </Content>
                <ButtonGroup>
                  <Button
                    variant="secondary"
                    onPress={() => {
                      setValidationErrors({});
                      closeUp();
                      handleDismiss();
                    }}
                  >
                    Skip
                  </Button>
                  <Button
                    isDisabled={isSubmitDisabled}
                    variant="cta"
                    onPress={async () => {
                      setSubmitDisabled(true);
                      setValidationErrors({});
                      const result = await handleSubmit();
                      (result && closeUp()) || setSubmitDisabled(false);
                    }}
                  >
                    Submit
                  </Button>
                </ButtonGroup>
              </Dialog>
            )}
          </DialogTrigger>
          <DialogTrigger
            type="popover"
            placement="left"
            offset={10}
            crossOffset={0}
            onOpenChange={(isOpen) => {
              !isOpen && handleDismiss();
            }}
          >
            <Button
              variant="cta"
              onPress={() => handleFeedback("down")}
              aria-label="Thumbs Down"
              aria-pressed={feedbackType === "down" ? "true" : "false"}
              UNSAFE_className="analytics-track-me"
              data-analytics-link-name="Thumbs Down"
              data-analytics-view-name="Feedback"
            >
              <ThumbDown color="notice" width="size-300" height="size-300" />
            </Button>
            {(closeDown) => (
              <Dialog onDismiss={handleDismiss} aria-label="feedback-dialog">
                <Heading>
                  <ThumbDown
                    aria-label="Thumbs Down"
                    color="notice"
                    width="size-300"
                    height="size-300"
                  />
                  <span>
                    We're sorry. Can you tell us{" "}
                    {pageTitle ? (
                      <>
                        in <em>{pageTitle}</em>
                      </>
                    ) : (
                      ""
                    )}{" "}
                    what didn't work for you?
                  </span>
                </Heading>
                <Content>
                  {validationErrors?.form && (
                    <InlineAlert variant="negative" autoFocus>
                      <Heading>Unable to submit</Heading>
                      <Content>{validationErrors.form}</Content>
                    </InlineAlert>
                  )}
                  <Form
                    validationBehavior="native"
                    maxWidth="size-5000"
                    validationErrors={validationErrors}
                  >
                    <Flex direction="column" gap="size-200">
                      <Flex direction="column" gap="size-0">
                        <Checkbox onBlur={handleCheckboxChange}>
                          Didn't find the answer to my question/problem
                        </Checkbox>
                        <Checkbox onBlur={handleCheckboxChange}>
                          Don't like this feature
                        </Checkbox>
                      </Flex>
                      <TextArea
                        minLength={50}
                        width={"100%"}
                        name={"commentDown"}
                        contextualHelp={
                          <ContextualHelp variant="info">
                            <Heading>Comment tips</Heading>
                            <Content>
                              Please enter a comment explaining the reason for
                              your vote. Please enter at least 50 characters.
                            </Content>
                          </ContextualHelp>
                        }
                        label={"* Details / Other comments"}
                        placeholder={"Type here"}
                        value={comment}
                        onChange={(value) => {
                          setComment(value);
                          setValidationErrors({});
                        }}
                      />
                    </Flex>
                  </Form>
                </Content>
                <ButtonGroup>
                  <Button
                    variant="secondary"
                    onPress={() => {
                      setValidationErrors({});
                      closeDown();
                      handleDismiss();
                    }}
                  >
                    Skip
                  </Button>
                  <Button
                    isDisabled={isSubmitDisabled}
                    variant="cta"
                    onPress={async () => {
                      setSubmitDisabled(true);
                      setValidationErrors({});
                      const result = await handleSubmit();
                      (result && closeDown()) || setSubmitDisabled(false);
                    }}
                  >
                    Submit
                  </Button>
                </ButtonGroup>
              </Dialog>
            )}
          </DialogTrigger>
        </Flex>
      </div>
    </View>
  );
}

export default FeedbackForm;
