/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import {
  ActionBarContainer,
  Cell,
  Column,
  Row,
  TableBody,
  TableHeader,
  TableView,
} from "@adobe/react-spectrum";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { useAccountDetails } from "../../hooks/useAccountDetails";
import CommonFilter from "../common/CommonFilter";
import MessageComponent from "../common/MessageComponent";
import SelectableTableCellContent from "../common/SelectableTableCellContent";

const EnvironmentDetailsTable = () => {
  const orgId = useSelector((state: RootState) => state.org.code);
  const {
    dynamicData,
    isOrgLoading,
    isProductFamiliesLoading,
    isEnvironmentsLoading,
    isProjectsLoading,
    isOrgError,
  } = useAccountDetails(orgId);

  const [filters, setFilters] = React.useState<Record<string, string | null>>({
    productFamilyFilter: null,
    projectFilter: null,
    environmentFilter: null,
  });

  const handleFilterChange = (newFilters: Record<string, string | null>) => {
    setFilters(newFilters);
  };

  const filteredData = dynamicData.filter((row) => {
    const matchesProductFamily = filters.productFamilyFilter
      ? row.productFamilyName === filters.productFamilyFilter
      : true;
    const matchesProject = filters.projectFilter
      ? row.projectName === filters.projectFilter
      : true;
    const matchesEnvironment = filters.environmentFilter
      ? row.environmentName === filters.environmentFilter
      : true;
    return matchesProductFamily && matchesProject && matchesEnvironment;
  });

  if (
    isOrgLoading ||
    isProductFamiliesLoading ||
    isProjectsLoading ||
    isEnvironmentsLoading
  ) {
    return <MessageComponent title="Loading" isLoading={true} />;
  }

  if (isOrgError) {
    return <MessageComponent title="Error Fetching Org Details" />;
  }

  return (
    <div>
      <ActionBarContainer>
        <CommonFilter
          filtersConfig={[
            {
              label: "Solution",
              key: "productFamilyFilter",
              options: Array.from(
                new Set(dynamicData.map((row) => row.productFamilyName)),
              ),
            },
            {
              label: "Provisioned Instance",
              key: "projectFilter",
              options: Array.from(
                new Set(dynamicData.map((row) => row.projectName)),
              ),
            },
            {
              label: "Environment",
              key: "environmentFilter",
              options: Array.from(
                new Set(dynamicData.map((row) => row.environmentName)),
              ),
            },
          ]}
          onFilterChange={handleFilterChange}
        />
        <TableView
          aria-label="Data points table"
          UNSAFE_className="table-container"
        >
          <TableHeader>
            <Column width="1fr">Solution</Column>
            <Column width="2fr">Provisioned Instance (Project, Program)</Column>
            <Column width="2fr">Environment (Host)</Column>
          </TableHeader>
          <TableBody>
            {filteredData.length > 0 ? (
              filteredData.map((row, index) => (
                <Row key={index}>
                  <Cell>
                    <SelectableTableCellContent>
                      {row.productFamilyName}
                    </SelectableTableCellContent>
                  </Cell>
                  <Cell>
                    <SelectableTableCellContent>
                      {row.projectName}
                    </SelectableTableCellContent>
                  </Cell>
                  <Cell>
                    <SelectableTableCellContent>
                      {row.environmentName}
                    </SelectableTableCellContent>
                  </Cell>
                </Row>
              ))
            ) : (
              <Row>
                <Cell>
                  <MessageComponent title="No data available">
                    No data available
                  </MessageComponent>
                </Cell>
                <Cell>{""}</Cell>
                <Cell>{""}</Cell>
              </Row>
            )}
          </TableBody>
        </TableView>
      </ActionBarContainer>
    </div>
  );
};

export default EnvironmentDetailsTable;
