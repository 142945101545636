/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { createSlice } from "@reduxjs/toolkit";
import { Case } from "../../types/case";

const initialState: Case = {
  casePayload: undefined,
  ticketResource: undefined,
  searchText: "",
  aiSummary: {
    isLoading: false,
    prompt: "",
    text: "",
  },
  aiSuggestions: {
    isLoading: false,
  },
  caseList: null,
  findings: [],
  isTicketRefreshing: false,
  isDataCollecting: false,
  internalNotes: [],
  instance: {
    projectId: null,
    environmentId: null,
  },
};

const caseSlice = createSlice({
  name: "case",
  initialState,
  reducers: {
    setCasePayload: (state, action) => {
      state.casePayload = { ...action.payload };
    },
    setTicketResource: (state, action) => {
      state.ticketResource = { ...action.payload };
    },
    setAiSummary: (state, action) => {
      state.aiSummary = { ...state.aiSummary, ...action.payload };
    },
    setAiSuggestions: (state, action) => {
      state.aiSuggestions = { ...state.aiSuggestions, ...action.payload };
    },
    setSearchText: (state, action) => {
      state.searchText = action.payload;
    },
    setCaseList: (state, action) => {
      state.caseList = { ...action.payload };
    },
    setFindings: (state, action) => {
      state.findings = [...action.payload];
    },
    setIsTicketRefreshing: (state, action) => {
      state.isTicketRefreshing = action.payload;
    },
    setIsDataCollecting: (state, action) => {
      state.isDataCollecting = action.payload;
    },
    setInternalNotes: (state, action) => {
      state.internalNotes = action.payload; // Replace all internalNotes
    },
    setInstance: (state, action) => {
      state.instance = { ...action.payload };
    },
    setInstanceProjectId(state, action) {
      state.instance.projectId = action.payload;
    },
    setInstanceEnvironmentId(state, action) {
      state.instance.environmentId = action.payload;
    },
    resetCase: () => initialState,
  },
});

export const {
  setCasePayload,
  setAiSummary,
  setAiSuggestions,
  setSearchText,
  setCaseList,
  setFindings,
  setIsTicketRefreshing,
  setIsDataCollecting,
  setInternalNotes,
  resetCase,
  setTicketResource,
  setInstance,
  setInstanceProjectId,
  setInstanceEnvironmentId,
} = caseSlice.actions;
export default caseSlice.reducer;
