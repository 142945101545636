/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store/store";
import {
  useGetEnvironmentListQuery,
  useUpdateBranchMutation,
} from "../services/dumpTool";
import {
  chooseBranch,
  chooseEnvironment,
  dumpInProgress,
  dumpNotFound,
  dumpUpdatingBranchInProgress,
  tableSizeNotCollected,
} from "../store/dump/dumpSlice";
import getProjectId from "../utils/getProjectId";

const useBranchSelector = () => {
  const dispatch = useDispatch();
  const dumpObject = useSelector((state: RootState) => state.dump.data);
  const defaultBranch = dumpObject.chosenBranch;
  const casePayload = useSelector((state: RootState) => state.case.casePayload);
  const productMetadata = casePayload?.productMetadata ?? [];
  const ticketId = casePayload?.caseId ?? "";
  const projectUrl =
    productMetadata.find((item) => item.displayName === "Project URL")?.value ||
    "";
  let projectId = "";
  if (projectUrl) {
    projectId = getProjectId(projectUrl);
  }
  const baseUrl = dumpObject.apiUrl;
  const { data, isLoading, error } = useGetEnvironmentListQuery({
    baseUrl: baseUrl,
    projectId,
    branch: defaultBranch,
    ticketId: ticketId,
  });
  const [
    updateBranch,
    {
      isLoading: updateBranchIsLoading,
      isSuccess,
      data: branchData,
      error: branchUpdateError,
    },
  ] = useUpdateBranchMutation();

  useEffect(() => {
    if (!isLoading && data) {
      dispatch(chooseEnvironment(data));
    }
  }, [data, dispatch, isLoading]);

  const chooseEnvironmentHandler = (selected: string) => {
    if (selected !== dumpObject.chosenBranch) {
      dispatch(chooseBranch(selected));
      setBranch(selected);
    }
  };

  const setBranch = (branch: string) => {
    if (!branch) {
      branch = "null";
    }
    dispatch(dumpUpdatingBranchInProgress("Updating choice of a branch..."));
    updateBranch({ baseUrl, branch, ticketId });

    if (!isSuccess) return;
    switch (branchData?.dump_status) {
      case "none":
        dispatch(tableSizeNotCollected());
        break;
      case "invalid_environment":
        dispatch(dumpInProgress("Could not update choice of a branch."));
        dispatch(chooseBranch(""));
        setTimeout(function () {
          dispatch(dumpNotFound());
        }, 4000);
        break;
    }
  };

  return { chooseEnvironmentHandler, dumpObject };
};

export default useBranchSelector;
