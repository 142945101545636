/*
 * ADOBE CONFIDENTIAL
 * Copyright 2024 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { Flex, Link, Text } from "@adobe/react-spectrum";
import WatchlistSwitch from "./WatchlistSwitch";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import LinkOut from "@spectrum-icons/workflow/LinkOut";

interface CaseNumberProps {
  dynamicsCaseId: string;
  ticketId: number;
}

const CaseNumber: React.FC<CaseNumberProps> = ({
  dynamicsCaseId,
  ticketId,
}) => {
  const casePayload = useSelector((state: RootState) => state.case.casePayload);
  const baseURL = window.env.REACT_APP_CASE_CONFIG_BASEURL;
  const caseURL = `${baseURL}${casePayload?.guid}`;
  return (
    <Flex gap={5} direction="column">
      <div>
        <Text UNSAFE_style={{ display: "inline-block", marginRight: "10px" }}>
          {dynamicsCaseId}
        </Text>
        <Link isQuiet href={caseURL} target="_blank" rel="noopener noreferrer">
          C14E
          <LinkOut
            aria-label="LinkOut"
            size="XS"
            UNSAFE_style={{ marginLeft: "8px", verticalAlign: "middle" }}
          />
        </Link>
      </div>
      <Text UNSAFE_style={{ fontWeight: "normal" }}>
        {casePayload?.statusText || "Unknown"}
      </Text>
      <WatchlistSwitch itemId={ticketId} itemType="ticket" />
    </Flex>
  );
};

export default CaseNumber;
