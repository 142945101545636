/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import { IMenuGroup, SidebarProps } from "../types/ISideNavbar";
import OrgSideBarConfig from "../config/OrgSideBarConfig";
import { Organization } from "../types/org";

function createOrgSidebarConfig(org: Organization | undefined): SidebarProps {
  const config: IMenuGroup = {
    "ORG DETAILS": [],
    "External Links": [],
  };

  if (!org) {
    return { config };
  }

  // Iterate over a copy of OrgSideBarConfig to avoid mutating the original array
  OrgSideBarConfig.forEach((originalItem) => {
    // Create a shallow copy of the item to prevent mutations
    const item = { ...originalItem };

    const type = item.LinkType;

    // Replace the placeholders in the URL with the actual values
    item.LinkURL = item.LinkURL.replace(":orgId", org.code);

    if (type === "Internal") {
      config["ORG DETAILS"].push(item);
      return;
    }

    if (type === "External") {
      config["External Links"].push(item);
      return;
    }

    if (!config[type]) {
      config[type] = []; // Initialize an empty array if this type hasn't been added to the config yet
    }
    config[type].push(item);
  });

  return { config };
}

export default createOrgSidebarConfig;
