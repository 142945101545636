/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useEffect } from "react";
import AccountDetailsTabContainer from "../components/augmentedAnalysis/AccountDetailsTabContainer";
import { useDispatch } from "react-redux";
import { setOrg } from "../store/org/orgSlice";
import { useParams } from "react-router-dom";
import { useGetOrganizationQuery } from "../services/supportInsights";
import MessageComponent from "../components/common/MessageComponent";
import { addRecentOrg } from "../store/userSlice";

const AccountDetailsPage: React.FC = () => {
  const dispatch = useDispatch();
  const { orgId } = useParams();

  const { data, isLoading, error } = useGetOrganizationQuery({
    orgId: orgId ?? "",
  });

  useEffect(() => {
    if (data?.data && data?.data.length === 1) {
      dispatch(setOrg(data.data[0]));
    }
  }, [data, isLoading, error, dispatch]);

  if (isLoading) {
    return <MessageComponent title={"Loading..."} isLoading={true} />;
  }

  if (data?.data && data?.data.length === 1) {
    dispatch(addRecentOrg(data.data[0]));
    return (
      <>
        <p className="account-details-page-header">Org Details</p>
        <div className="account-details">
          <span className="account-details-orgid">
            Full Org Name:
            <span className="orgId-color">
              {data.data[0].name ? data.data[0].name : "Not Provided"}
            </span>
          </span>
        </div>
        <AccountDetailsTabContainer />
      </>
    );
  }

  return null;
};

export default AccountDetailsPage;
