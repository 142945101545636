/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import BoxIcon from "@spectrum-icons/workflow/Box";
import LinkIcon from "@spectrum-icons/workflow/Link";
import GlobeOutline from "@spectrum-icons/workflow/GlobeOutline";

export const caseIcons = {
  OASIS: <BoxIcon aria-label="" UNSAFE_className="sidebar-group-items-icon" />,
  "External Links": (
    <LinkIcon aria-label="" UNSAFE_className="sidebar-group-items-icon" />
  ),
};

export const orgIcons = {
  "ORG DETAILS": (
    <GlobeOutline aria-label="" UNSAFE_className="sidebar-group-items-icon" />
  ),
  "External Links": (
    <LinkIcon aria-label="" UNSAFE_className="sidebar-group-items-icon" />
  ),
};
