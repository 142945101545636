/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */
import { Link } from "@adobe/react-spectrum";
import React from "react";
import { FindingModalProps, FindingResource } from "../../types/finding";
import Modal from "./Modal";
import { DataAggregator } from "../../utils/dataAggregator";
import { Format, convertAndCopyForFindings } from "../../utils/dataExportUtils";
import { parseAndSanitizeMarkdown } from "../../utils/sanitizeMarkdown";
import MarkdownRenderer from "../../utils/renderMarkdown";

const FindingModal: React.FC<FindingModalProps> = ({ finding }) => {
  const documentationContent =
    parseAndSanitizeMarkdown(finding.finding_declaration.documentation) +
    `<img src="data:null;," onerror="hydrateLinks(this)">`;

  return (
    <Modal
      dialogDownloadFun={convertAndCopyForFindings}
      dialogJSON={[finding.datapoint.data]}
      dialogDownloadFormat={Format.pdf}
      dialogHeader={finding.finding_declaration.name}
      dialogShowSourceBtn={false}
      contentType="Finding"
      trigger={
        <Link
          UNSAFE_className="analytics-track-me"
          data-analytics-link-name={finding.finding_declaration.name}
          data-analytics-view-name="Findings List"
        >
          {finding.finding_declaration.name}
        </Link>
      }
      dialogShowDownloadBtn={true} // FIXME
    >
      <h2 className="modal-section-title">Recommendation</h2>
      {formatFindings(finding)}
      <h2 className="modal-section-title">Documentation</h2>
      <MarkdownRenderer content={documentationContent} isSanitizedHTML />
    </Modal>
  );
};

export default FindingModal;

function formatFindings(finding: FindingResource) {
  const { recommendation } = finding;
  let __html = parseAndSanitizeMarkdown(recommendation);
  const includesDatapoint = recommendation.includes("{{ datapoint }}");

  const aggregator = new DataAggregator();
  //@ts-ignore
  aggregator.add([finding.datapoint]);

  const datapointHTMLID = crypto.randomUUID();

  if (includesDatapoint) {
    __html = __html.replace(
      "{{ datapoint }}",
      // HACK: Use something other than images to move the datapoint table
      `<img src="data:null;," onerror="hydrateAggregated(this)" data-replace-with="${datapointHTMLID}">`,
    );
  }

  const hydrateAggregated = () => {
    if (!includesDatapoint) return null;
    return <div id={datapointHTMLID}>{aggregator.render()}</div>;
  };
  return (
    <>
      {/*Content is Sanitized in step above*/}
      <MarkdownRenderer content={__html} isSanitizedHTML />
      <div hidden>{hydrateAggregated()}</div>
    </>
  );
}

// @ts-expect-error -- HACK: Use something other than images to move the datapoint table
window.hydrateAggregated ??= (element: HTMLElement) => {
  const uuid = element.getAttribute("data-replace-with");
  if (!uuid) return;
  const container = document.getElementById(uuid);
  if (!container) return;
  element.parentElement?.replaceChild(container, element);
};
