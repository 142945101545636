/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { View } from "@adobe/react-spectrum";
import "../../styles/main.scss";
import ChevronRightIcon from "@spectrum-icons/workflow/ChevronRight";
import { SidebarProps } from "../../types/ISideNavbar";

const SideNavBar: React.FC<
  SidebarProps & {
    groupIcons?: Record<string, React.ReactNode>;
  }
> = ({ config, groupIcons = {} }) => {
  const [openGroup, setOpenGroup] = useState(Object.keys(config));

  const activeLinkClass = "sidebar-item analytics-track-me active-style-Link";
  const sidebarItemClass = "sidebar-item analytics-track-me";

  const toggleGroup = (groupName: string) => {
    setOpenGroup((prevOpenGroups) =>
      prevOpenGroups.includes(groupName)
        ? prevOpenGroups.filter((group) => group !== groupName)
        : [...prevOpenGroups, groupName],
    );
  };

  const getIconRotationStyle = (groupName: string) => ({
    transform: openGroup.includes(groupName) ? "rotate(90deg)" : "rotate(0deg)",
    transition: "transform 0.3s ease",
  });

  return (
    <View padding="size-400">
      <nav className="sidebar">
        {Object.entries(config).map(([groupName, items]) => (
          <div key={groupName} className="sidebar-group">
            <button
              className="sidebar-group-heading analytics-track-me"
              data-analytics-view-name="Sidebar"
              onClick={() => toggleGroup(groupName)}
            >
              {groupIcons[groupName] || (
                <span className="sidebar-default-icon" aria-hidden />
              )}
              {groupName}
              <ChevronRightIcon
                aria-label=""
                UNSAFE_className="sidebar-group-toggle-icon"
                UNSAFE_style={getIconRotationStyle(groupName)}
              />
            </button>
            {openGroup.includes(groupName) && (
              <div className="sidebar-group-items">
                {items.map((item, index) => (
                  <NavLink
                    key={`${groupName}_${index}`}
                    className={({ isActive }) =>
                      isActive ? activeLinkClass : sidebarItemClass
                    }
                    to={item.LinkURL}
                    target={item.LinkType === "External" ? "_blank" : "_self"}
                    rel={
                      item.LinkType === "External"
                        ? "noopener noreferrer"
                        : undefined
                    }
                    data-analytics-link-name={item.LinkName}
                    data-analytics-link-type={
                      item.LinkType === "External" ? "exit" : ""
                    }
                    data-analytics-view-name="Sidebar"
                  >
                    {item.LinkName}
                  </NavLink>
                ))}
              </div>
            )}
          </div>
        ))}
      </nav>
    </View>
  );
};

export default SideNavBar;
