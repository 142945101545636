/*
 * ADOBE CONFIDENTIAL
 * Copyright 2025 Adobe
 * All Rights Reserved.
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 */

import React from "react";
import { View, Flex, ActionButton, Text } from "@adobe/react-spectrum";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/svg/adobe_logo.svg";
import Tag from "../../assets/svg/Tag.svg";
import ProfilePicture from "../../assets/profile.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import Banner from "./Banner";
import { useDispatch } from "react-redux";
import { setSearchText } from "../../store/case/caseSlice";
import Bug from "@spectrum-icons/workflow/Bug";
import Feature from "@spectrum-icons/workflow/Feature";
import BugReporter from "../../utils/BugReporter";

interface NavbarProps {
  sticky?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ sticky = false }) => {
  const user = useSelector((state: RootState) => state.user.userDetails);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleLogoClick = () => {
    navigate("/");
    dispatch(setSearchText(""));
  };

  const openLink = (url: string) => {
    window.open(url, "_blank");
  };

  return (
    <View UNSAFE_className="sticky-navbar" height={"single-line-height"}>
      <Flex
        alignItems="center"
        justifyContent="start"
        direction="row"
        height="100%"
      >
        <div className="navbar-adobeLogo-div" onClick={handleLogoClick}>
          <img className="navbar-adobeLogo" src={logo} alt=""></img>
        </div>
        <div className="navbar-left-col-title" onClick={handleLogoClick}>
          DX Oasis
        </div>

        <Flex UNSAFE_className="navbar-left-col-Beta">
          <img src={Tag} alt="" aria-label="in Beta" />
        </Flex>
      </Flex>
      <Banner />

      <Flex alignItems="center" gap="size-100">
        <ActionButton
          staticColor="white"
          isQuiet
          onPress={() => openLink(BugReporter.featureRequestURL)}
          UNSAFE_className="analytics-track-me"
          data-analytics-link-name="Feature Request"
          data-analytics-link-type="exit"
          data-analytics-view-name="Header"
        >
          <Feature />
          <Text>Feature Request</Text>
        </ActionButton>
        <ActionButton
          staticColor="white"
          isQuiet
          onPress={() => openLink(BugReporter.formURL)}
          UNSAFE_className="analytics-track-me"
          data-analytics-link-name="Report a Bug"
          data-analytics-link-type="exit"
          data-analytics-view-name="Header"
        >
          <Bug />
          <Text>Report a Bug</Text>
        </ActionButton>
        <img
          className="navbar-avatar"
          alt=""
          src={`https://s7d2.scene7.com/is/image/IMGDIR/${user?.userid || ""}?$70x$`}
          onError={(ev) => (ev.currentTarget.src = ProfilePicture)}
        />
      </Flex>
    </View>
  );
};

export default Navbar;
